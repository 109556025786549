import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import EffectSelector from '../effect-selector/EffectSelector.vue';
import Element from '@/model/entity/card/element';
import ElementConfigInterface from '@/model/interface/card/element-config-interface';
import ElementDto from '@/model/entity/card/element-dto';
import MediaSelector from '../media-selector/MediaSelector.vue';
import { cloneDeep } from 'lodash';

@Component({
    components:
    {
        'app-effect-selector': EffectSelector,
        'app-media-selector': MediaSelector,
    },
})
export default class ElementEditorComponent extends Vue
{
    @Prop()
    private value!: Element;

    private element: Element = new Element(new ElementDto());
    private config: ElementConfigInterface = {
        position: {
            x1: '0.00',
            y1: '0.00',
            x2: '100.00',
            y2: '100.00',
        },
        align: {
            horizontal: 'centre',
            vertical: 'middle',
        },
        fit: 'contain',
    };

    private get xRange()
    {
        return [ this.config.position.x1, this.config.position.x2 ];
    }

    private set xRange(range)
    {
        this.config.position.x1 = range[0].toString();
        this.config.position.x2 = range[1].toString();

        this.onUpdate();
    }

    private get yRange()
    {
        return [ this.config.position.y1, this.config.position.y2 ];
    }

    private set yRange(range)
    {
        this.config.position.y1 = range[0].toString();
        this.config.position.y2 = range[1].toString();

        this.onUpdate();
    }

    private get hexaBackgroundColour(): string
    {
        return this.getPickerColour(this.config.background?.colour ?? null);
    }

    private set hexaBackgroundColour(hexa: string)
    {
        const colour = this.getColourFromPicker(hexa);
        if (!colour)
        {
            if (this.config.background)
            {
                delete this.config.background;
            }

            return;
        }

        this.config.background =
        {
            colour,
        };

        this.onUpdate();
    }

    private get hexaTextColour(): string
    {
        return this.getPickerColour(this.config.text?.colour ?? null);
    }

    private set hexaTextColour(hexa: string)
    {
        if (!this.config.text)
        {
            this.config.text = this.getDefaultTextConfig();
        }

        const colour = this.getColourFromPicker(hexa);
        if (colour)
        {
            this.config.text.colour = colour;
        }
        else
        {
            this.config.text.colour = '#000000';
        }

        this.onUpdate();
    }

    private get dropShadow(): boolean
    {
        return this.config.text?.dropShadow ?? false;
    }

    private set dropShadow(dropShadow: boolean)
    {
        if (!this.config.text)
        {
            this.config.text = this.getDefaultTextConfig();
        }

        if (dropShadow)
        {
            this.config.text.dropShadow = true;
            this.config.text.dropShadowColour = '#000000';
            this.config.text.dropShadowBlur = 0;
            this.config.text.dropShadowDistance = 5;
        }
        else if (this.config.text?.dropShadow)
        {
            delete this.config.text.dropShadow;
        }

        this.onUpdate();
    }

    private created()
    {
        this.onValueChanged();
    }

    @Watch('value')
    private onValueChanged()
    {
        this.element = this.value;

        // Copy the current config
        this.config = Object.assign({}, cloneDeep(this.element.config));

        // Depending on the type, ensure values exist for the configs
        switch (this.element.type)
        {
        case 'audio':
            break;

        case 'effect':
            if (!this.config.effect)
            {
                this.config.effect = this.getDefaultEffectConfig();
            }
            break;

        case 'image':
            break;

        case 'text':
            if (!this.config.text)
            {
                this.config.text = this.getDefaultTextConfig();
            }
            break;

        case 'video':
            break;

        default:
        }
    }

    /**
     * Returns the default effect config
     *
     * @returns
     */

    private getDefaultEffectConfig()
    {
        return {
            id: '',
        };
    }

    /**
     * Returns the default text config
     *
     * @returns
     */

    private getDefaultTextConfig()
    {
        return {
            text: 'New text',
            font: 'Arial',
            size: '10.00',
            colour: '#000000',
        };
    }

    /**
     * Handles value updates
     */

    private onUpdate()
    {
        this.element.config = cloneDeep(this.config);

        this.$emit('input', this.element);
    }

    /**
     * Returns a normalised colour from the vuetify picker component
     *
     * @param hexa
     * @returns
     */

    private getColourFromPicker(hexa: string): string|null
    {
        if (hexa.substr(7, 2) === 'FF')
        {
            return hexa.substr(0, 7);
        }

        // If the alpha component is 00 (totally transparent) then set the colour to null
        else if (hexa.substr(7, 2) === '00')
        {
            return null;
        }

        // There's a weird bug with pixi.js not liking partially transparent pure black...making it blue.
        // So make it not quite pure black as a cheat.
        // @todo possibly fix somehow!
        else if (hexa.substr(0, 7) === '#000000' && hexa.substr(7, 2) !== 'FF')
        {
            return `#010101${ hexa.substr(7, 2) }`;
        }
        else
        {
            return hexa;
        }
    }

    /**
      * Returns a vuetify picker compatible hexa colour
      *
      * @param colour
      * @returns
      */

    private getPickerColour(colour: string|null): string
    {
        if (!colour)
        {
            return '#00000000';
        }
        else if (colour.length === 7)
        {
            return `${ colour }FF`;
        }

        // There's a weird bug with pixi.js not liking partially transparent pure black...making it blue.
        // So this converts a hacky non-pure black back to pure black.
        // @todo possibly fix somehow!
        else if (colour === '#010101' || (colour.substr(0, 7) === '#010101' && colour.substr(7, 2) !== 'FF'))
        {
            return '#000000FF';
        }
        else
        {
            return colour;
        }
    }
}