import AbstractTask from '../abstract-task';
import Category from '@/model/entity/taxonomy/category';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';

export default class GetCategoryTask extends AbstractTask
{
    public id = '';

    public async run(): Promise<Category>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Category(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.CategoryNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/categories/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }
}