import AbstractTask from '../abstract-task';
import Card from '@/model/entity/card/card';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';

export default class GetCardTask extends AbstractTask
{
    public id = '';

    public async run(): Promise<Card>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Card(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.CardNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/cards/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }
}