/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export default {
    namespaced: true,
    state: (): any => ({
        loginDialogVisible: false,
        navigationDrawerVisible: true,
    }),
    mutations: {
        loginDialogVisible(state: any, loginDialogVisible: boolean): void
        {
            state.loginDialogVisible = loginDialogVisible;
        },
        navigationDrawerVisible(state: any, navigationDrawerVisible: boolean): void
        {
            state.navigationDrawerVisible = navigationDrawerVisible;
        },
    },
    actions: {},
    getters: {
        loginDialogVisible(state: any): boolean
        {
            return state.loginDialogVisible;
        },
        navigationDrawerVisible(state: any): boolean
        {
            return state.navigationDrawerVisible;
        },
    },
};