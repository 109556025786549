import { find, findIndex } from 'lodash';

import Element from './element';
import ElementDto from './element-dto';
import LayerDto from './layer-dto';
import Page from './page';
import { v4 as uuidv4 } from 'uuid';

export default class Layer
{
    dto!: LayerDto;

    public constructor(dto: LayerDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get page(): Page|null
    {
        if (!this.dto.page)
        {
            return null;
        }

        return new Page(this.dto.page);
    }

    public set page(page: Page|null)
    {
        if (!page)
        {
            this.dto.page = null;
            return;
        }

        this.dto.page = Object.assign({}, page.dto);
    }

    public get side(): string
    {
        return this.dto.side;
    }

    public set side(side: string)
    {
        this.dto.side = side;
    }

    public get zIndex(): number
    {
        return this.dto.zIndex;
    }

    public set zIndex(zIndex: number)
    {
        this.dto.zIndex = zIndex;
    }

    public get dateCreated(): string
    {
        return this.dto.dateCreated;
    }

    public set dateCreated(dateCreated: string)
    {
        this.dto.dateCreated = dateCreated;
    }

    public get dateUpdated(): string
    {
        return this.dto.dateUpdated;
    }

    public set dateUpdated(dateUpdated: string)
    {
        this.dto.dateUpdated = dateUpdated;
    }

    public get elements(): Element[]
    {
        const elements: Element[] = [];

        for (const elementDto of this.dto.elements)
        {
            elements.push(new Element(elementDto));
        }

        return elements;
    }

    public set elements(elements: Element[])
    {
        this.dto.elements = [];

        for (const element of elements)
        {
            this.dto.elements.push(element.dto);
        }
    }

    /**
     * Adds a new element of the give type to the layer
     *
     * @param type
     * @returns
     */

    public createElement(type: string): Element
    {
        // Create a new element
        const element = new Element(new ElementDto());
        element.id = uuidv4();
        element.layer = new Layer(this.dto);
        element.type = type;

        switch (type)
        {
        case 'effect':
            element.config.effect = {
                id: '',
            };

            break;

        case 'image':
            break;

        case 'text':
            element.config.text = {
                text: 'New text',
                font: 'Arial',
                size: '10.00',
                colour: '#000000',
            };

            break;
        }

        // Add to the array of elements
        this.elements = [ ...this.elements, element ];

        // Return the new element
        return element;
    }

    /**
     * Updates the given element in the layer
     *
     * @param element
     * @returns
     */

    public updateElement(element: Element): void
    {
        // Get the element's index in the layer
        const index = findIndex(this.elements, { id: element.id });
        if (index < 0)
        {
            return;
        }

        // Update the element in the layer
        const elements = [ ...this.elements ];
        elements.splice(
            index, 1, element,
        );

        this.elements = elements;
    }

    /**
     * Deletes an element from the layer
     *
     * @param id
     * @returns
     */

    public deleteElement(id: string): Element|null
    {
        const index = findIndex(this.elements, { id });
        if (index < 0)
        {
            return null;
        }

        const elements = [ ...this.elements ];
        const removed = elements.splice(index, 1);

        this.elements = elements;

        return removed[0];
    }

    /**
     * Returns the element with the given id
     *
     * @param id
     * @returns
     */

    public getElement(id: string): Element|null
    {
        const element = find(this.elements, { id });

        return element ?? null;
    }
}