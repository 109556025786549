import { formatISO, parseISO } from 'date-fns';

import UserDto from './user-dto';

export default class User
{
    dto!: UserDto;

    public constructor(dto: UserDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get email(): string
    {
        return this.dto.email;
    }

    public set email(email: string)
    {
        this.dto.email = email;
    }

    public get plainPassword(): string|null
    {
        return this.dto.plainPassword;
    }

    public set plainPassword(plainPassword: string|null)
    {
        this.dto.plainPassword = plainPassword;
    }

    public get firstName(): string|null
    {
        return this.dto.firstName;
    }

    public set firstName(firstName: string|null)
    {
        this.dto.firstName = firstName;
    }

    public get lastName(): string|null
    {
        return this.dto.lastName;
    }

    public set lastName(lastName: string|null)
    {
        this.dto.lastName = lastName;
    }

    public get dateRegistered(): Date
    {
        return parseISO(this.dto.dateRegistered);
    }

    public set dateRegistered(dateRegistered: Date)
    {
        this.dto.dateRegistered = formatISO(dateRegistered);
    }

    public get roles(): string[]
    {
        return this.dto.roles;
    }

    public set roles(roles: string[])
    {
        this.dto.roles = roles;
    }
}