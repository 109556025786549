var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.type')))]),_c('v-select',{attrs:{"items":[
            {value: 'audio', text: _vm.$t('asset.audio')},
            {value: 'image', text: _vm.$t('asset.image')},
            {value: 'video', text: _vm.$t('asset.video')},
        ],"solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.asset.type),callback:function ($$v) {_vm.$set(_vm.asset, "type", $$v)},expression:"asset.type"}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.title')))]),_c('v-text-field',{attrs:{"rules":_vm.titleRules,"required":"","solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.asset.title),callback:function ($$v) {_vm.$set(_vm.asset, "title", $$v)},expression:"asset.title"}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.licence_type')))]),_c('v-select',{attrs:{"items":[
            {value: 'attribution', text: _vm.$t('licence.attribution')},
            {value: 'purchased', text: _vm.$t('licence.purchased')},
            {value: 'royalty-free', text: _vm.$t('licence.royalty_free')},
        ],"solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.asset.licenceType),callback:function ($$v) {_vm.$set(_vm.asset, "licenceType", $$v)},expression:"asset.licenceType"}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.licence_attribution')))]),(_vm.asset.licenceType === 'attribution')?_c('v-textarea',{attrs:{"solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.asset.licenceAttribution),callback:function ($$v) {_vm.$set(_vm.asset, "licenceAttribution", $$v)},expression:"asset.licenceAttribution"}}):_vm._e(),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.licence_details')))]),_c('v-textarea',{attrs:{"solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.asset.licenceDetails),callback:function ($$v) {_vm.$set(_vm.asset, "licenceDetails", $$v)},expression:"asset.licenceDetails"}}),_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('field.tags')))]),_c('app-tag-selector',{model:{value:(_vm.asset.tags),callback:function ($$v) {_vm.$set(_vm.asset, "tags", $$v)},expression:"asset.tags"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }