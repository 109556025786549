import { Component, Vue } from 'vue-property-decorator';

import Event from '@/event/event';
import { EventBus } from '@/model/helper/event-bus';
import Header from '@/components/header/Header.vue';
import LoginDialog from '@/components/login-dialog/LoginDialog.vue';
import NavigationDrawer from '@/components/navigation-drawer/NavigationDrawer.vue';

@Component({
    components:
    {
        'app-header': Header,
        'app-login-dialog': LoginDialog,
        'app-navigation-drawer': NavigationDrawer,
    },
})
export default class App extends Vue
{
    private created(): void
    {
        this.initEvents();
    }

    private get loginDialogVisible(): boolean
    {
        return this.$store.getters['ui/loginDialogVisible'];
    }

    private set loginDialogVisible(loginDialogVisible: boolean)
    {
        this.$store.commit('ui/loginDialogVisible', loginDialogVisible);
    }

    private get navigationDrawerVisible(): boolean
    {
        return this.$store.getters['ui/navigationDrawerVisible'];
    }

    private set navigationDrawerVisible(navigationDrawerVisible: boolean)
    {
        this.$store.commit('ui/navigationDrawerVisible', navigationDrawerVisible);
    }

    private toggleNavigationDrawer()
    {
        this.$store.commit('ui/navigationDrawerVisible', !this.navigationDrawerVisible);
    }

    /**
     * Initialises global event listeners
     */

    private initEvents()
    {
        // Listen to login requests
        EventBus.$on('show-login', () => this.onShowLogin());

        // Listen to logout requests
        EventBus.$on('logout', () => this.onLogout());

        // Listen to session timeout events
        EventBus.$on(Event.SessionExpired, () => this.onSessionExpired());
    }

    /**
     * Handles show login action
     */

    private onShowLogin()
    {
        if (this.$user)
        {
            return;
        }

        this.loginDialogVisible = true;
    }

    /**
     * Handles logout action
     */

    private onLogout()
    {
        this.$serviceContainer.authenticationService.logout();
    }

    /**
     * Handles session expiry
     */

    private onSessionExpired()
    {
        // Redirect to the homepage
        this.$router.push({ name: 'home' });

        // Show the login popup
        this.onShowLogin();
    }
}