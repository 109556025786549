import * as particles from '@pixi/particle-emitter';

import ParticleEffectInterface from '../particle-effect-interface';

export default class SnowEffect implements ParticleEffectInterface
{
    private static MAX_FALL_TIME = 5000; // Time for snow to fall in MS
    private static PARTICLE_BASE_SCALE = 0.15 / 700; // Base scale for the particles based on a standard height of 700px

    public getParticleImages(): string[]
    {
        return [ require('@/assets/images/particles/weather/snow-flake.png') ];
    }

    public getParticlesConfig(width: number, height: number): particles.EmitterConfigV3
    {
        // Calculate the fall time in speed in seconds
        // Note: Speed seems to be half number of pixels per second?
        const speedFallTime = SnowEffect.MAX_FALL_TIME * 0.5 * 0.001;

        // Set an appropriate spawn position for the particles
        const spawnX = -(width * 0.5);
        const spawnY = -10;
        const spawnWidth = width + Math.abs(spawnX);
        const spawnHeight = 0;

        // Calculate the full height including spawn position
        const fullHeight = height + Math.abs(spawnY);

        // Calculate the min and max speeds for the particles
        const maxSpeed = fullHeight / speedFallTime;
        const minSpeed = maxSpeed * 0.5;

        // Calculate the min and max particle lifetimes
        const minLifetime = (fullHeight / maxSpeed) + 1;
        const maxLifetime = (fullHeight / minSpeed) + 1;

        // Calculate the scale of the particles
        const maxScale = SnowEffect.PARTICLE_BASE_SCALE * height;
        const minScale = maxScale * 0.75;

        return {
            behaviors: [
                {
                    type: 'alpha',
                    config: {
                        alpha: {
                            list: [
                                {
                                    value: 1,
                                    time: 0,
                                },
                                {
                                    value: 0.5,
                                    time: 1,
                                },
                            ],
                        },
                    },
                },
                {
                    type: 'scale',
                    config: {
                        scale: {
                            list: [
                                {
                                    value: minScale,
                                    time: 0,
                                },
                                {
                                    value: maxScale,
                                    time: 1,
                                },
                            ],
                        },
                        minMult: 0.5,
                    },
                },
                {
                    type: 'moveSpeed',
                    config: {
                        speed: {
                            list: [
                                {
                                    value: minSpeed,
                                    time: 0,
                                },
                                {
                                    value: maxSpeed,
                                    time: 1,
                                },
                            ],
                        },
                    },
                },
                {
                    type: 'rotation',
                    config: {
                        minSpeed: -100,
                        maxSpeed: 100,
                        minStart: 80,
                        maxStart: 110,
                    },
                },
                {
                    type: 'spawnShape',
                    config: {
                        type: 'rect',
                        data: {
                            x: spawnX,
                            y: spawnY,
                            w: spawnWidth,
                            h: spawnHeight,
                        },
                    },
                },
                {
                    type: 'textureSingle',
                    config: {
                        texture: require('@/assets/images/particles/weather/snow-flake.png'),
                    },
                },
            ],
            lifetime: {
                min: minLifetime,
                max: maxLifetime,
            },
            ease: [
                {
                    s: 0,
                    cp: 0.379,
                    e: 0.548,
                },
                {
                    s: 0.548,
                    cp: 0.717,
                    e: 0.676,
                },
                {
                    s: 0.676,
                    cp: 0.635,
                    e: 1,
                },
            ],
            frequency: 0.015,
            maxParticles: 400,
            pos: {
                x: 0,
                y: 0,
            },
        };
    }

    public getPreWarmTime(): number
    {
        return 10;
    }
}