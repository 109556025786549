import AssetDto from './asset-dto';

export default class Asset
{
    dto!: AssetDto;

    public constructor(dto: AssetDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get type(): string
    {
        return this.dto.type;
    }

    public set type(type: string)
    {
        this.dto.type = type;
    }

    public get title(): string
    {
        return this.dto.title;
    }

    public set title(title: string)
    {
        this.dto.title = title;
    }

    public get originalFilename(): string
    {
        return this.dto.originalFilename;
    }

    public set originalFilename(originalFilename: string)
    {
        this.dto.originalFilename = originalFilename;
    }

    public get path(): string
    {
        return this.dto.path;
    }

    public set path(path: string)
    {
        this.dto.path = path;
    }

    public get size(): number
    {
        return this.dto.size;
    }

    public set size(size: number)
    {
        this.dto.size = size;
    }

    public get licenceType(): string
    {
        return this.dto.licenceType;
    }

    public set licenceType(licenceType: string)
    {
        this.dto.licenceType = licenceType;
    }

    public get licenceDetails(): string
    {
        return this.dto.licenceDetails;
    }

    public set licenceDetails(licenceDetails: string)
    {
        this.dto.licenceDetails = licenceDetails;
    }

    public get licenceAttribution(): string|null
    {
        return this.dto.licenceAttribution;
    }

    public set licenceAttribution(licenceAttribution: string|null)
    {
        this.dto.licenceAttribution = licenceAttribution;
    }

    public get dateCreated(): string
    {
        return this.dto.dateCreated;
    }

    public set dateCreated(dateCreated: string)
    {
        this.dto.dateCreated = dateCreated;
    }

    public get dateUpdated(): string
    {
        return this.dto.dateUpdated;
    }

    public set dateUpdated(dateUpdated: string)
    {
        this.dto.dateUpdated = dateUpdated;
    }

    public get file(): Blob|null
    {
        return this.dto.file;
    }

    public set file(file: Blob|null)
    {
        this.dto.file = file;
    }
}