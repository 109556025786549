import AbstractTask from '../abstract-task';
import Element from '@/model/entity/card/element';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';

export default class PutElementTask extends AbstractTask
{
    public element!: Element;

    public async run(): Promise<Element>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Element(response.data);
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/template_elements/${ this.element.id }`;
    }

    protected get method(): Method
    {
        return 'PUT';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: {[key: string]: any} = Object.assign({}, this.element.dto);

        if (this.element.layer)
        {
            data.layer = `/api/template_layers/${ this.element.layer.id }`;
        }

        if (this.element.asset)
        {
            data.asset = `/api/assets/${ this.element.asset.id }`;
        }

        return data;
    }
}