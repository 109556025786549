import { Component, Vue } from 'vue-property-decorator';

import CategoryWizardComponent from '@/components/category-wizard/CategoryWizard.vue';
import SuggestedCardsComponent from '@/components/suggested-cards/SuggestedCards.vue';

@Component({
    components:
    {
        'app-category-wizard': CategoryWizardComponent,
        'app-suggested-cards': SuggestedCardsComponent,
    },
})
export default class Home extends Vue
{
}