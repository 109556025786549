import TagDto from './tag-dto';

export default class Tag
{
    dto!: TagDto;

    public constructor(dto: TagDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get title(): string
    {
        return this.dto.title;
    }

    public set title(title: string)
    {
        this.dto.title = title;
    }
}