import { Component, Prop, Vue } from 'vue-property-decorator';

import { LocaleMessage } from 'vue-i18n';
import { VForm } from 'vuetify/lib';

@Component
export default class ResetPasswordForm extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    public $refs: any = {
        form: VForm,
    };

    private valid = false;

    private password = '';
    private confirmationPassword = '';

    private showPassword = false;
    private showConfirmationPassword = false;

    private passwordRules =
        [ (v: string) => !!v || this.$t('validation.password_required') ];

    private confirmationPasswordRules =
        [ this.validatePasswordsMatch ];

    /**
     * Validates the form
     */

    private validate()
    {
        this.valid = this.$refs.form.validate();

        return this.valid;
    }

    /**
     * Validates the confirmation password
     */

    private validatePasswordsMatch(): boolean|LocaleMessage
    {
        return this.confirmationPassword === this.password || this.$t('validation.passwords_must_match');
    }

    /**
     * Handles toggle show password action
     */

    private onToggleShowPassword()
    {
        this.showPassword = !this.showPassword;
    }

    /**
     * Handles toggle show confirmation password action
     */

    private onToggleShowConfirmationPassword()
    {
        this.showConfirmationPassword = !this.showConfirmationPassword;
    }

    /**
     * Handles reset password action
     */

    private onResetPassword()
    {
        if (!this.valid)
        {
            return;
        }

        this.$emit('reset-password', this.password);
    }
}