import AbstractAuthenticatedService from '../abstract-authenticated-service';
import Asset from '@/model/entity/asset/asset';
import AssetSearchConditionsInterface from '@/model/interface/asset/asset-search-conditions-interface';
import AssetSearchResultsInterface from '@/model/interface/asset/asset-search-results-interface';
import GetAssetTask from '@/model/task/asset/get-asset';
import GetAssetsTask from '@/model/task/asset/get-assets';
import PostAssetTask from '@/model/task/asset/post-asset';
export default class AssetService extends AbstractAuthenticatedService
{
    /**
     * Finds matching assets
     *
     * @param searchConditions
     */

    public async findAssets(searchConditions: AssetSearchConditionsInterface|null): Promise<AssetSearchResultsInterface>
    {
        const task = new GetAssetsTask();

        if (searchConditions)
        {
            task.searchConditions = searchConditions;
        }

        return this.runAuthenticatedTask(task) as Promise<AssetSearchResultsInterface>;
    }

    /**
     * Loads a asset by id
     *
     * @param id
     */

    public async loadAsset(id: string): Promise<Asset>
    {
        const task = new GetAssetTask();
        task.id = id;

        return task.run();
    }

    /**
     * Creates a asset
     *
     * @param asset
     * @returns
     */

    public async createAsset(asset: Asset): Promise<Asset>
    {
        const task = new PostAssetTask();
        task.asset = asset;

        return this.runAuthenticatedTask(task) as Promise<Asset>;
    }

    /**
     * Returns the URL for the asset file
     *
     * @param asset
     * @returns
     */

    public static getAssetFileUrl(asset: Asset): string
    {
        return `${ process.env.VUE_APP_BASE_URL }/assets/${ asset.id }/${ asset.path }`;
    }
}