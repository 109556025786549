import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { VForm } from 'vuetify/lib';

@Component
export default class LoginForm extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    public $refs: any = {
        form: VForm,
    };

    private valid = false;

    private email = '';
    private password = '';

    private customerType = 'existing';
    private showPassword = false;

    private emailRules =
        [ (v: string) => !!v || this.$t('validation.email_required'), (v: string) => /.+@.+\..+/.test(v) || this.$t('validation.email_invalid') ];

    private passwordRules =
        [ (v: string) => !!v || this.$t('validation.password_required') ];

    @Watch('customerType')
    private onCustomerTypeChanged()
    {
        this.$refs.form.resetValidation();
    }

    /**
     * Returns whether the user is a new customer
     */

    private get isNewCustomer(): boolean
    {
        return (this.customerType === 'new');
    }

    /**
     * Handles toggle show password action
     */

    private onToggleShowPassword()
    {
        this.showPassword = !this.showPassword;
    }

    /**
     * Handles login action
     */

    private onLogin()
    {
        if (!this.valid)
        {
            return;
        }

        this.$emit(
            'login', this.email, this.password,
        );
    }

    /**
     * Handle reset password action
     */

    private onResetPassword()
    {
        this.$emit('show-reset-password', this.email);
    }

    /**
     * Handles register action
     */

    private onRegister()
    {
        this.$emit('show-register', this.email);
    }
}