import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import Template from '@/model/entity/card/template';

export default class PostTemplateTask extends AbstractTask
{
    public template!: Template;

    public async run(): Promise<Template>
    {
        const response = await this.execute();

        if (response.status === 201)
        {
            return new Template(response.data);
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/templates';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: {[key: string]: any} = Object.assign({}, this.template.dto);
        delete data.id;

        this.updateCategoriesAssociation(this.template, data);
        this.updateTagsAssociation(this.template, data);

        return data;
    }
}