import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ForgottenPasswordForm extends Vue
{
    @Prop()
    private email!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    private valid = false;

    private resetEmail = '';

    private emailRules =
        [ (v: string) => !!v || this.$t('validation.email_required'), (v: string) => /.+@.+\..+/.test(v) || this.$t('validation.email_invalid') ];

    private created()
    {
        this.resetEmail = this.email;
    }

    /**
     * Handles reset password action
     */

    private onResetPassword()
    {
        if (!this.valid)
        {
            return;
        }

        this.$emit('reset-password', this.resetEmail);
    }
}