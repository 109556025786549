import AdminHome from '@/views/admin/home/AdminHome.vue';
import AdminTemplateCreate from '@/views/admin/template/create/AdminTemplateCreate.vue';
import AdminTemplateEdit from '@/views/admin/template/edit/AdminTemplateEdit.vue';
import Card from '@/views/card/Card.vue';
import Category from '@/views/category/Category.vue';
import Home from '@/views/home/Home.vue';
import PasswordReset from '@/views/password-reset/PasswordReset.vue';
import ResetPassword from '@/views/reset-password/ResetPassword.vue';
import { RouteConfig } from 'vue-router';
import Template from '@/views/template/Template.vue';

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordReset,
    },
    {
        path: '/my-card/:id',
        name: 'card',
        component: Card,
    },
    {
        path: '/cards/:slug',
        name: 'category',
        component: Category,
    },
    {
        path: '/card/:slug',
        name: 'template',
        component: Template,
    },
    {
        path: '/admin',
        name: 'admin-home',
        component: AdminHome,
    },
    {
        path: '/admin/card/create',
        name: 'admin-template-create',
        component: AdminTemplateCreate,
    },
    {
        path: '/admin/card/:slug',
        name: 'admin-template-edit',
        component: AdminTemplateEdit,
    },
];

export default routes;