import { Component, Vue } from 'vue-property-decorator';

import Category from '@/model/entity/taxonomy/category';
import CategoryPromoComponent from '../category-promo/CategoryPromo.vue';

@Component({
    components:
    {
        'app-category-promo': CategoryPromoComponent,
    },
})
export default class CategoryWizardComponent extends Vue
{
    private categories: Category[] = [];

    private created()
    {
        this.loadCategories();
    }

    /**
     * Loads the categories
     */

    private async loadCategories()
    {
        this.categories = await this.$serviceContainer.categoryService.loadNavigationCategories();
    }
}