import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CardComponent from '../card/Card.vue';
import Category from '@/model/entity/taxonomy/category';
import Template from '@/model/entity/card/template';

@Component({
    components:
    {
        'app-card': CardComponent,
    },
})
export default class CategoryPromoComponent extends Vue
{
    @Prop({
        type: Object,
        default: undefined,
    })
    private category!: Category;

    @Prop({
        type: Object,
        default: undefined,
    })
    private template!: Template|undefined;

    private promoTemplate: Template|null = null;

    private created()
    {
        this.assignPromoCard();
    }

    @Watch('category')
    private onCategoryChanged()
    {
        this.assignPromoCard();
    }

    @Watch('card')
    private onCardChanged()
    {
        this.assignPromoCard();
    }

    /**
     * Assigns the promo card template
     */

    private async assignPromoCard()
    {
        if (this.template)
        {
            this.promoTemplate = Object.assign({}, this.template);
        }
        else
        {
            const results = await this.$serviceContainer.templateService.findTemplates({
                'categories.slug': this.category.slug,
                'itemsPerPage': 1,
                'page': 1,
            });

            if (results.totalResults > 0)
            {
                this.promoTemplate = results.results[0];
            }
            else
            {
                this.promoTemplate = null;
            }
        }
    }
}