import { Component, Prop, Vue } from 'vue-property-decorator';

import { LocaleMessage } from 'vue-i18n';
import User from '@/model/entity/organisation/user';
import { VForm } from 'vuetify/lib';
import { formatISO } from 'date-fns';

@Component
export default class RegisterForm extends Vue
{
    @Prop()
    private email!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private loading!: boolean;

    public $refs: any = {
        form: VForm,
    };

    private valid = false;

    private user: User = new User({
        id: '',
        email: '',
        plainPassword: '',
        firstName: '',
        lastName: '',
        dateRegistered: '',
        roles: [],
    });

    private confirmationPassword = '';

    private showPassword = false;
    private showConfirmationPassword = false;

    private emailRules =
        [ (v: string) => !!v || this.$t('validation.email_required'), (v: string) => /.+@.+\..+/.test(v) || this.$t('validation.email_invalid') ];

    private passwordRules =
        [ (v: string) => !!v || this.$t('validation.password_required') ];

    private confirmationPasswordRules =
        [ this.validatePasswordsMatch ];

    private firstNameRules =
        [ (v: string) => !!v || this.$t('validation.first_name_required') ];

    private lastNameRules =
        [ (v: string) => !!v || this.$t('validation.last_name_required') ];

    private created()
    {
        this.user = new User({
            id: '',
            email: this.email,
            plainPassword: '',
            firstName: '',
            lastName: '',
            dateRegistered: formatISO(new Date()),
            roles: [],
        });
    }

    /**
     * Validates the form
     */

    private validate()
    {
        this.valid = this.$refs.form.validate();

        return this.valid;
    }

    /**
     * Validates the confirmation password
     */

    private validatePasswordsMatch(): boolean|LocaleMessage
    {
        return this.confirmationPassword === this.user.plainPassword || this.$t('validation.passwords_must_match');
    }

    /**
     * Handles toggle show password action
     */

    private onToggleShowPassword()
    {
        this.showPassword = !this.showPassword;
    }

    /**
     * Handles toggle show confirmation password action
     */

    private onToggleShowConfirmationPassword()
    {
        this.showConfirmationPassword = !this.showConfirmationPassword;
    }

    /**
     * Handles register action
     */

    private onRegister()
    {
        // Re-validate incase password was changed
        if (!this.validate())
        {
            this.valid = false;

            return;
        }

        this.$emit('register', this.user);
    }
}