import { Component, Vue } from 'vue-property-decorator';

import ResetPasswordForm from '@/components/reset-password-form/ResetPasswordForm.vue';

@Component({
    components:
    {
        'app-reset-password-form': ResetPasswordForm,
    },
})
export default class ResetPassword extends Vue
{
    private token = '';

    private loading = false;
    private failed = false;

    private created()
    {
        this.token = this.$route.query.token as string;
    }

    /**
     * Handle reset password action
     *
     * @param password
     */

    private async onResetPassword(password: string)
    {
        this.loading = true;

        try
        {
            await this.$serviceContainer.authenticationService.resetPassword(this.token, password);

            this.$router.replace({ name: 'password-reset' });
        }
        catch (error)
        {
            this.failed = true;
        }
        finally
        {
            this.loading = false;
        }
    }
}