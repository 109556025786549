export default class TimingsHelper
{
    /**
     * Allows creating delays in code
     *
     * @param ms
     */

    public static delay(ms: number): Promise<void>
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Runs code ensuring a minimum time passes - useful for loaders
     *
     * @param code
     * @param ms
     */

    public static async runCodeWithMinimumTime(code: () => void, ms: number): Promise<void>
    {
        const start = new Date();

        await code();

        const end = new Date();

        if (end.getMilliseconds() - start.getMilliseconds() < ms)
        {
            await TimingsHelper.delay(ms);
        }
    }
}