import { Component, Vue, Watch } from 'vue-property-decorator';

import CardComponent from '@/components/card/Card.vue';
import Template from '@/model/entity/card/template';

@Component({
    components:
    {
        'app-card': CardComponent,
    },
})
export default class TemplateView extends Vue
{
    private template: Template|null = null;

    created(): void
    {
        this.loadTemplate();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        // If the slug has changed, the template has changed
        if (this.$route.params.slug !== this.template?.slug)
        {
            this.loadTemplate();
        }
    }

    /**
     * Returns whether the card should be book-like
     */

    private get bookLike(): boolean
    {
        return this.$screen.width >= 1024;
    }

    /**
     * Loads the current template
     */

    private async loadTemplate()
    {
        const template = await this.$serviceContainer.templateService.loadTemplate(this.$route.params.slug);
        if (!template)
        {
            return;
        }

        // Set the template
        this.template = template;
    }
}