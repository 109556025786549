import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import User from '@/model/entity/organisation/user';

export default class PostUserTask extends AbstractTask
{
    public user: User|null = null;

    public async run(): Promise<User>
    {
        const response = await this.execute();

        if (response.status === 201)
        {
            return new User(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/users';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        if (!this.user)
        {
            return null;
        }

        return {
            email: this.user.email,
            plainPassword: this.user.plainPassword,
        };
    }
}