import { Component, Prop, Vue } from 'vue-property-decorator';

import Card from '@/model/entity/card/card';
import { format } from 'date-fns';

@Component
export default class EnvelopeComponent extends Vue
{
    @Prop()
    private card!: Card;

    private turned = false;

    /**
     * Returns the stamp asset url
     */

    private get stampUrl(): string
    {
        const now = new Date();

        // Christmas
        if (format(now, 'M') === '12')
        {
            return require('@/assets/images/stamps/christmas.svg');
        }

        // Normal
        else
        {
            return require('@/assets/images/stamps/london.svg');
        }
    }

    /**
     * Returns the formatted received date of the card
     */

    private get receivedDate(): string
    {
        if (!this.card.dateSent)
        {
            return '';
        }

        return format(this.card.dateSent, 'yyyy-MM-dd');
    }

    /**
     * Returns the formatted received time of the card
     */

    private get receivedTime(): string
    {
        if (!this.card.dateSent)
        {
            return '';
        }

        return format(this.card.dateSent, 'HH:mm');
    }

    /**
     * Handles mouse enter events
     */

    private onEnter()
    {
        if (this.$screen.width >= 1024)
        {
            this.turned = true;
        }
    }

    /**
     * Handles mouse leave events
     */

    private onLeave()
    {
        this.turned = false;
    }

    /**
     * Handles click events
     *
     * @returns
     */

    private onClick()
    {
        if (!this.turned)
        {
            this.turned = true;
            return;
        }

        this.$emit('opened');
    }
}