import AbstractTask from '../abstract-task';
import Asset from '@/model/entity/asset/asset';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';

export default class GetAssetTask extends AbstractTask
{
    public id = '';

    public async run(): Promise<Asset>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Asset(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.AssetNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/assets/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'GET';
    }
}