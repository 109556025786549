import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import CategorisableInterface from '../interface/taxonomy/categorisable-interface';
import Exception from '../exception/exception';
import OrderInterface from '../interface/search/order-interface';
import ParamsInterface from '../interface/search/params-interface';
import TaggableInterface from '../interface/taxonomy/taggable-interface';
import TaskInterface from './task-interface';
import store from '@/store';

export default abstract class AbstractTask implements TaskInterface
{
    public abstract run(): Promise<unknown>;

    protected async execute(): Promise<AxiosResponse>
    {
        const config: AxiosRequestConfig =
        {
            baseURL: process.env.VUE_APP_API_BASE_URL,
            url: this.url,
            method: this.method,
            params: this.params,
            data: this.data,
        };

        // Set headers
        config.headers = {};

        // Add the bearer token if the request is authenticated
        if (this.authenticated)
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            config.headers.Authorization = `Bearer ${ (store as any).getters['authentication/token'] }`;
        }

        // Set the content type to multipart/form-data if the data is FormData
        if (this.data instanceof FormData)
        {
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        // Get the request response
        try
        {
            const response = await axios.request(config);

            return response;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            // Throw a TokenExpired exception if this is the failure
            if (this.authenticated && this.isTokenExpired(error.response))
            {
                throw new Error(Exception.TokenExpired);
            }

            // Otherwise return the response as the code will be handled by the concrete task
            else
            {
                return error.response;
            }
        }
    }

    protected abstract get url(): string;

    protected abstract get method(): Method;

    protected get authenticated(): boolean
    {
        return true;
    }

    protected get params(): ParamsInterface|null
    {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|FormData|null
    {
        return null;
    }

    /**
     * Returns whether the token has expired
     *
     * @param response
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected isTokenExpired(response: AxiosResponse<any>): boolean
    {
        return (response.status === 401 && response.data.message === 'Expired JWT Token');
    }

    /**
     * Generates params from a data object
     *
     * @param data
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected generateParamsFromData(data: {[key: string]: any}|null): ParamsInterface|null
    {
        if (!data)
        {
            return null;
        }

        const params: ParamsInterface = {};

        for (const key in data)
        {
            // Sorting
            if (key === 'order')
            {
                const values: OrderInterface[] = data[key];
                for (const value of values)
                {
                    params[`order[${ value.key }]`] = value.descending ? 'desc' : 'asc';
                }
            }

            // Conditions
            else
            {
                const value = data[key];

                if (Array.isArray(value) && value.length > 0)
                {
                    const values: string[] = [];

                    for (const currentValue of value)
                    {
                        values.push(this.getParamValue(currentValue));
                    }

                    params[key] = values;
                }
                else if (value !== '' && value !== null && value !== undefined)
                {
                    params[key] = this.getParamValue(value);
                }
            }
        }

        return params;
    }

    /**
     * Returns a string value for a param value
     *
     * @param value
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getParamValue(value: any): string
    {
        switch (typeof value)
        {
        case 'boolean':
            return value ? '1' : '0';

        case 'bigint':
        case 'number':
            return `${ value }`;

        case 'symbol':
            return value.description as string;

        case 'string':
        default:
            return value;
        }
    }

    /**
     * Updates the categories association on a data object
     *
     * @param entity
     * @param data
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    protected updateCategoriesAssociation(entity: CategorisableInterface, data: any): void
    {
        if (entity.categories)
        {
            const categories: string[] = [];

            for (const category of entity.categories)
            {
                categories.push(`/api/categories/${ category.id }`);
            }

            data.categories = categories;
        }
    }

    /**
     * Updates the tags association on a data object
     *
     * @param entity
     * @param data
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
    protected updateTagsAssociation(entity: TaggableInterface, data: any): void
    {
        if (entity.tags)
        {
            const tags: {id?: string, title?: string}[] = [];

            for (const tag of entity.tags)
            {
                if (typeof tag === 'string')
                {
                    tags.push({
                        title: tag,
                    });
                }
                else if (tag.id && tag.id !== tag.title)
                {
                    tags.push({
                        id: `/api/tags/${ tag.id }`,
                    });
                }
                else
                {
                    tags.push({
                        title: tag.title,
                    });
                }
            }

            data.tags = tags;
        }
    }
}