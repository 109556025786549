import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CategorySelector from '../category-selector/CategorySelector.vue';
import TagSelector from '../tag-selector/TagSelector.vue';
import Template from '@/model/entity/card/template';

@Component({
    components:
    {
        'app-category-selector': CategorySelector,
        'app-tag-selector': TagSelector,
    },
})
export default class TemplateFormComponent extends Vue
{
    @Prop()
    private value!: Template;

    private template!: Template;
    private valid = false;

    private titleRules =
        [ (v: string) => !!v || this.$t('validation.title_required') ];

    private slugRules =
        [ (v: string) => !!v || this.$t('validation.slug_required') ];

    private created()
    {
        this.template = this.value;
    }

    @Watch('value')
    private onValueChanged()
    {
        this.template = this.value;
    }

    /**
     * Handles value updates
     */

    private onUpdate()
    {
        this.$emit('input', this.template);
    }
}