import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import LoginResponseInterface from '@/model/interface/authentication/login-response-interface';
import { Method } from 'axios';

export default class LoginTask extends AbstractTask
{
    public email = '';
    public password = '';

    public async run(): Promise<LoginResponseInterface>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return response.data;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/authentication/login';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    protected get data(): {[key: string]: string}
    {
        return {
            email: this.email,
            password: this.password,
        };
    }
}