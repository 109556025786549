import AbstractTask from '../abstract-task';
import Category from '@/model/entity/taxonomy/category';
import CategorySearchConditionsInterface from '@/model/interface/taxonomy/category-search-conditions-interface';
import { Method } from 'axios';
import ParamsInterface from '@/model/interface/search/params-interface';

export default class GetCategoriesTask extends AbstractTask
{
    public searchConditions?: CategorySearchConditionsInterface;

    public async run(): Promise<Category[]>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const categories: Category[] = [];

            for (const member of response.data['hydra:member'])
            {
                const category = new Category(member);
                categories.push(category);
            }

            return categories;
        }
        else
        {
            return [];
        }
    }

    protected get url(): string
    {
        return '/categories';
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    protected get params(): ParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}