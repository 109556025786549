import Asset from '../asset/asset';
import ElementConfigInterface from '@/model/interface/card/element-config-interface';
import ElementDto from './element-dto';
import Layer from './layer';

export default class Element
{
    dto!: ElementDto;

    public constructor(dto: ElementDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get layer(): Layer|null
    {
        if (!this.dto.layer)
        {
            return null;
        }

        return new Layer(this.dto.layer);
    }

    public set layer(layer: Layer|null)
    {
        if (!layer)
        {
            this.dto.layer = null;
            return;
        }

        this.dto.layer = Object.assign({}, layer.dto);
    }

    public get type(): string
    {
        return this.dto.type;
    }

    public set type(type: string)
    {
        this.dto.type = type;
    }

    public get asset(): Asset|null
    {
        if (!this.dto.asset)
        {
            return null;
        }

        return new Asset(this.dto.asset);
    }

    public set asset(asset: Asset|null)
    {
        if (!asset)
        {
            this.dto.asset = null;
            return;
        }

        this.dto.asset = Object.assign({}, asset.dto);
    }

    public get config(): ElementConfigInterface
    {
        return this.dto.config;
    }

    public set config(config: ElementConfigInterface)
    {
        this.dto.config = Object.assign({}, config);
    }

    public get dateCreated(): string
    {
        return this.dto.dateCreated;
    }

    public set dateCreated(dateCreated: string)
    {
        this.dto.dateCreated = dateCreated;
    }

    public get dateUpdated(): string
    {
        return this.dto.dateUpdated;
    }

    public set dateUpdated(dateUpdated: string)
    {
        this.dto.dateUpdated = dateUpdated;
    }
}