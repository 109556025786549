import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import Page from '@/model/entity/card/page';

export default class PutPageTask extends AbstractTask
{
    public page!: Page;

    public async run(): Promise<Page>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Page(response.data);
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/template_pages/${ this.page.id }`;
    }

    protected get method(): Method
    {
        return 'PUT';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: {[key: string]: any} = Object.assign({}, this.page.dto);

        if (this.page.template)
        {
            data.template = `/api/templates/${ this.page.template.id }`;
        }

        return data;
    }
}