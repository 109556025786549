import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import User from '@/model/entity/organisation/user';

export default class GetUserTask extends AbstractTask
{
    public id = '';

    public async run(): Promise<User>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new User(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.UserNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/users/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'GET';
    }
}