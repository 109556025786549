import AssetService from './asset/asset-service';
import AuthenticationService from './authentication/authentication-service';
import CardService from './card/card-service';
import CategoryService from './taxonomy/category-service';
import TagService from './taxonomy/tag-service';
import TemplateService from './card/template-service';

export default class ServiceContainer
{
    public get assetService(): AssetService
    {
        return new AssetService(this.authenticationService);
    }

    public get authenticationService(): AuthenticationService
    {
        return new AuthenticationService();
    }

    public get cardService(): CardService
    {
        return new CardService(this.authenticationService);
    }

    public get categoryService(): CategoryService
    {
        return new CategoryService(this.authenticationService);
    }

    public get tagService(): TagService
    {
        return new TagService(this.authenticationService);
    }

    public get templateService(): TemplateService
    {
        return new TemplateService(this.authenticationService);
    }
}