import { Component, Prop, Vue } from 'vue-property-decorator';

import { EventBus } from '@/model/helper/event-bus';
import FilterWizardComponent from '../filter-wizard/FilterWizard.vue';

@Component({
    components:
    {
        'app-filter-wizard': FilterWizardComponent,
    },
})
export default class NavigationDrawer extends Vue
{
    @Prop({
        type: Boolean,
        default: false,
    })
    private value!: boolean;

    private get visible(): boolean
    {
        // Hide on the my-card page (for now!)
        if (this.$route.name === 'card')
        {
            return false;
        }

        // Disable for non-admin users (for now!)
        if (!this.$user?.roles.includes('ROLE_ADMIN'))
        {
            return false;
        }

        return this.value;
    }

    private set visible(visible: boolean)
    {
        this.$emit('input', visible);
    }

    /**
     * Handles login action
     */

    private onLogin()
    {
        EventBus.$emit('show-login');
    }

    /**
     * Handles logout action
     */

    private onLogout()
    {
        EventBus.$emit('logout');
    }
}