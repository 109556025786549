import AbstractSearchTask from '../abstract-search-task';
import Asset from '@/model/entity/asset/asset';
import AssetDto from '@/model/entity/asset/asset-dto';
import AssetSearchConditionsInterface from '@/model/interface/asset/asset-search-conditions-interface';
import AssetSearchResultsInterface from '@/model/interface/asset/asset-search-results-interface';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import ParamsInterface from '@/model/interface/search/params-interface';

export default class GetAssetsTask extends AbstractSearchTask
{
    public searchConditions?: AssetSearchConditionsInterface;

    public async run(): Promise<AssetSearchResultsInterface>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);

            const assets: Asset[] = [];
            for (const assetDto of searchResults.results)
            {
                assets.push(new Asset(assetDto as AssetDto));
            }

            searchResults.results = assets;

            return searchResults as AssetSearchResultsInterface;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/assets';
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get params(): ParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}