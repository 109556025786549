import Event from '@/event/event';
import { EventBus } from '@/model/helper/event-bus';
import Exception from '@/model/exception/exception';
import ForgottenPasswordTask from '@/model/task/authentication/forgotten-password-task';
import GetUserTask from '@/model/task/organisation/get-user-task';
import LoginResponseInterface from '@/model/interface/authentication/login-response-interface';
import LoginTask from '@/model/task/authentication/login-task';
import PostUserTask from '@/model/task/organisation/post-user-task';
import RefreshTokenTask from '@/model/task/authentication/refresh-token-task';
import ResetPasswordTask from '@/model/task/authentication/reset-password-task';
import TimingsHelper from '@/model/helper/timings-helper';
import User from '@/model/entity/organisation/user';
import store from '@/store';

export default class AuthenticationService
{
    /**
     * Registers an account
     *
     * @param user
     */

    public async register(user: User): Promise<User>
    {
        const task = new PostUserTask();
        task.user = user;

        return task.run();
    }

    /**
     * Sends a forgotten password request
     *
     * @param email
     */

    public async forgottenPassword(email: string): Promise<boolean>
    {
        const task = new ForgottenPasswordTask();
        task.email = email;

        return task.run();
    }

    /**
     * Resets a password
     *
     * @param token
     * @param password
     */

    public async resetPassword(token: string, password: string): Promise<boolean>
    {
        const task = new ResetPasswordTask();
        task.token = token;
        task.password = password;

        return task.run();
    }

    /**
     * Attempts to log the user in
     *
     * @param email
     * @param password
     */

    public async login(email: string, password: string): Promise<User>
    {
        // Attempt to login
        const loginTask = new LoginTask();
        loginTask.email = email;
        loginTask.password = password;

        // Update credentials
        const loginResponse = await loginTask.run();
        await this.updateCredentials(loginResponse);

        // Update and return the current user
        return this.updateUser(loginResponse.data.id);
    }

    /**
     * Logs the current user out
     */

    public async logout(): Promise<void>
    {
        store.commit('authentication/token', null);
        store.commit('authentication/refreshToken', null);
        store.commit('authentication/user', null);
    }

    /**
     * Returns whether the user is logged in
     *
     * @returns
     */

    public get isLoggedIn(): boolean
    {
        return store.getters['authentication/user'] && store.getters['authentication/token'];
    }

    /**
     * Refreshes the authentication token
     */

    public async refreshToken(): Promise<void>
    {
        try
        {
            // Only allow single refresh token requests
            if (store.getters['authentication/refreshingToken'])
            {
                // Wait until the other request has finished attempting the refresh
                while (store.getters['authentication/refreshingToken'])
                {
                    await TimingsHelper.delay(50);
                }

                // The other request should have already refreshed the token - or failed
                return;
            }

            // Mark the fact this call is refreshing the token to prevent others from running at the same time
            store.commit('authentication/refreshingToken', true);

            // Attempt to renew the token using the refresh token
            const task = new RefreshTokenTask();
            task.refreshToken = store.getters['authentication/refreshToken'];

            // Update credentials
            const loginResponse = await task.run();
            await this.updateCredentials(loginResponse);

            // Update the current user
            await this.updateUser(loginResponse.data.id);

            // Mark as no longer refreshing the token
            store.commit('authentication/refreshingToken', false);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            switch (error.message)
            {
            case Exception.RefreshTokenExpired:
                // Send a message to indicate the session has expired
                EventBus.$emit(Event.SessionExpired);

                // Log the user out
                this.logout();

                // Mark as no longer refreshing the token
                store.commit('authentication/refreshingToken', false);

                break;

            default:
                // Mark as no longer refreshing the token
                store.commit('authentication/refreshingToken', false);

                throw error;
            }
        }
    }

    /**
     * Updates the user's credentials
     *
     * @param credentials
     */

    private async updateCredentials(loginResponse: LoginResponseInterface)
    {
        store.commit('authentication/token', loginResponse.token);
        store.commit('authentication/refreshToken', loginResponse.refreshToken);
    }

    /**
     * Updates the current user
     *
     * @param id
     * @returns
     */

    private async updateUser(id: string): Promise<User>
    {
        // Load the details of the logged in user
        const userTask = new GetUserTask();
        userTask.id = id;

        const user = await userTask.run();
        if (!user)
        {
            throw new Error(Exception.UserNotFound);
        }

        // Store the user
        store.commit('authentication/user', user);

        // Return the logged in user
        return user;
    }
}