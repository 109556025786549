import { Component, Prop, Watch } from 'vue-property-decorator';

import CardComponent from '../card/Card.vue';
import Category from '@/model/entity/taxonomy/category';
import SearchMixin from '@/mixins/search-mixin';
import TemplateSearchConditionsInterface from '@/model/interface/card/template-search-conditions-interface';
import TemplateSearchResultsInterface from '@/model/interface/card/template-search-results-interface';
import { mixins } from 'vue-class-component';

@Component({
    components:
    {
        'app-card': CardComponent,
    },
})
export default class TemplateIndexComponent extends mixins(SearchMixin)
{
    @Prop()
    private category!: Category;

    protected conditions: TemplateSearchConditionsInterface|null = null;
    protected results: TemplateSearchResultsInterface|null = null;
    private loading = false;

    private created()
    {
        this.resetSearchConditions();
        this.resetSearchResults();

        this.loadTemplates();
    }

    @Watch('category')
    private onCategoryChanged()
    {
        this.resetSearchConditions();
        this.resetSearchResults();

        this.loadTemplates();
    }

    protected resetSearchConditions(): void
    {
        this.conditions = Object.assign({}, {
            'categories.slug': this.category.slug,
            'itemsPerPage': 20,
            'page': 1,
        });
    }

    /**
     * Loads the template for the current category
     */

    private async loadTemplates()
    {
        this.loading = true;

        try
        {
            const results = await this.$serviceContainer.templateService.findTemplates(this.conditions);

            this.results = Object.assign({}, results);
        }
        finally
        {
            this.loading = false;
        }
    }
}