import { Component, Vue, Watch } from 'vue-property-decorator';

import Template from '@/model/entity/card/template';
import TemplateEditor from '@/components/template-editor/TemplateEditor.vue';

@Component({
    components:
    {
        'app-template-editor': TemplateEditor,
    },
})
export default class AdminTemplateEdit extends Vue
{
    private template: Template|null = null;
    private originalTemplate: Template|null = null;

    created(): void
    {
        this.loadTemplate();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        // If the slug has changed, the template has changed
        if (this.$route.params.slug !== this.template?.slug)
        {
            this.loadTemplate();
        }
    }

    /**
     * Loads the current template
     */

    private async loadTemplate()
    {
        const template = await this.$serviceContainer.templateService.loadTemplate(this.$route.params.slug);
        if (!template)
        {
            return;
        }

        // Set the template
        this.template = template;
        this.originalTemplate = new Template(Object.assign({}, this.template.dto));
    }

    /**
     * Handles saved events
     */

    private onSaved()
    {
        this.originalTemplate = this.template ? new Template(Object.assign({}, this.template.dto)) : null;
    }
}