import { Component, Vue } from 'vue-property-decorator';

import Category from '@/model/entity/taxonomy/category';

@Component({})
export default class FilterWizardComponent extends Vue
{
    private categories: Category[] = [];
    private tags: string[] = [];

    private created()
    {
        this.loadCategories();
    }

    /**
     * Loads the categories
     */

    private async loadCategories()
    {
        this.categories = await this.$serviceContainer.categoryService.loadNavigationCategories();
    }

    private onCategorySelected()
    {
        // Maybe watch the route and get the category from that
        if (this.$route.name !== 'category')
        {
            this.tags = [];

            return;
        }

        this.loadCategoryTags();
    }

    private loadCategoryTags()
    {
        // Load the tags that exist for templates in the current category
    }
}