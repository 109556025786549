import CategoryDto from '../taxonomy/category-dto';
import PageDto from './page-dto';
import TagDto from '../taxonomy/tag-dto';

export default class TemplateDto
{
    id = '';
    slug = '';
    title = '';
    description: string|null = null;
    isPublic = false;
    pages: PageDto[] = [];
    categories: CategoryDto[] = [];
    tags: TagDto[] = [];
    dateCreated = '';
    dateUpdated = '';
}