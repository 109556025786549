var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"effect-selector"},[_c('v-select',{attrs:{"items":[
            {header: 'Nature'},
            {value: 'particle/nature/petals', text: 'Petals'},
            {header: 'Smoke'},
            {value: 'particle/smoke/chimney', text: 'Chimney'},
            {header: 'Weather'},
            {value: 'particle/weather/snow', text: 'Snow'},
            {value: 'particle/weather/snow-thick', text: 'Snow Thick'},
        ],"solo":"","hide-details":""},on:{"change":_vm.onUpdate},model:{value:(_vm.effect),callback:function ($$v) {_vm.effect=$$v},expression:"effect"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }