import * as FontFaceObserver from 'fontfaceobserver';
import * as PIXI from 'pixi.js';
import * as particles from '@pixi/particle-emitter';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Asset from '@/model/entity/asset/asset';
import AssetService from '@/model/service/asset/asset-service';
import ChimneySmokeEffect from '@/effects/particle/smoke/chimney-effect';
import Decimal from 'decimal.js-light';
import Element from '@/model/entity/card/element';
import LayerComponent from '../layer/Layer.vue';
import MathsHelper from '@/model/helper/maths-helper';
import Page from '@/model/entity/card/page';
import ParticleEffectInterface from '@/effects/particle/particle-effect-interface';
import PetalsEffect from '@/effects/particle/nature/petals-effect';
import SnowEffect from '@/effects/particle/weather/snow-effect';
import SnowThickEffect from '@/effects/particle/weather/snow-thick-effect';
import { debounce } from 'lodash';

@Component({
    components:
    {
        'app-layer': LayerComponent,
    },
})
export default class PageComponent extends Vue
{
    @Prop()
    private page!: Page;

    @Prop()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateOverrides!: any;

    @Prop({
        type: Boolean,
        default: false,
    })
    private static!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private turned!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private front!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private back!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private hidden!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public $refs: any = {
        page: HTMLElement,
        canvasFront: HTMLCanvasElement,
        canvasBack: HTMLCanvasElement,
    };

    private sides: string[] = [
        'front',
        'back',
    ];

    private pixiFront!: PIXI.Application;
    private pixiBack!: PIXI.Application;

    private pageWidth = 0;
    private pageHeight = 0;

    private pixiMappings: {
        layers: {[id: string]: PIXI.Container},
        elements: {[id: string]: PIXI.Container|PIXI.Sprite},
        emitters: {[id: string]: particles.Emitter},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        emitterListeners: {[id: string]: PIXI.TickerCallback<any>}
    } = {
            layers: {},
            elements: {},
            emitters: {},
            emitterListeners: {},
        };

    private mounted()
    {
        // Update page size
        this.pageWidth = this.$refs.page.offsetWidth;
        this.pageHeight = this.$refs.page.offsetHeight;

        // Initialise Pixi
        this.initPixi();
    }

    private destroyed()
    {
        this.deinitPixi();
    }

    @Watch('page')
    private debouncedOnPageChange = debounce(this.onPageChange, 250);

    private onPageChange(newPage: Page, oldPage: Page)
    {
        // Only process if it's actually changed
        if (JSON.stringify(newPage.dto) === JSON.stringify(oldPage.dto))
        {
            return;
        }

        this.reinitPixi();
    }

    @Watch('$screen.width')
    private onWindowWidthChange()
    {
        this.onWindowResize();
    }

    @Watch('$screen.height')
    private onWindowHeightChange()
    {
        this.onWindowResize();
    }

    /**
     * Handles window resize events
     */

    private onWindowResize()
    {
        // Update page size
        this.pageWidth = this.$refs.page.offsetWidth;
        this.pageHeight = this.$refs.page.offsetHeight;

        // Re-init pixi
        this.reinitPixi();
    }

    /**
     * Returns whether the given side of the page should be generated with pixi
     *
     * @param side
     * @returns
     */

    private sideIsPixi(side: string): boolean
    {
        if (this.static)
        {
            return false;
        }

        for (const layer of this.page.layers)
        {
            if (layer.side !== side)
            {
                continue;
            }

            for (const element of layer.elements)
            {
                if (element.type === 'effect')
                {
                    return true;
                }
            }
        }

        return false;
    }

    private getCanvasForSide(side: string): HTMLCanvasElement|null
    {
        if (!this.sideIsPixi(side))
        {
            return null;
        }

        return side === 'front' ? this.$refs.canvasFront[0] : this.$refs.canvasBack[0];
    }

    /**
     * Initialises Pixi rendering of the page
     */

    private async initPixi()
    {
        this.initSidePixi('front');
        this.initSidePixi('back');
    }

    /**
     * Inits pixi for a given side
     *
     * @param side
     * @returns
     */

    private initSidePixi(side: string)
    {
        if (!this.sideIsPixi(side))
        {
            return;
        }

        try
        {
            // Get the canvas
            const canvas = this.getCanvasForSide(side);
            if (!canvas)
            {
                return;
            }

            // Set higher resolution to stop text from being blurry
            if (this.pageWidth < 1024)
            {
                PIXI.settings.RESOLUTION = 2;
            }

            // Init the pixi application
            const pixi = new PIXI.Application({
                view: canvas,
                width: this.pageWidth,
                height: this.pageHeight,
                backgroundAlpha: 0,
            });

            if (side === 'front')
            {
                this.pixiFront = pixi;
            }
            else
            {
                this.pixiBack = pixi;
            }

            // Allow touch bubbling to the DOM
            pixi.renderer.plugins.interaction.autoPreventDefault = false;

            // Add the content
            this.addPixiLayers(pixi, side);
        }
        catch (err)
        {
            console.error(err);
        }
    }

    /**
     * Re-initialises pixi
     */

    private async reinitPixi()
    {
        // Destroy all emitters
        for (const id in this.pixiMappings.emitterListeners)
        {
            const emitterListener = this.pixiMappings.emitterListeners[id];

            if (this.pixiFront)
            {
                this.pixiFront.ticker.remove(emitterListener);
            }

            if (this.pixiBack)
            {
                this.pixiBack.ticker.remove(emitterListener);
            }
        }

        for (const id in this.pixiMappings.emitters)
        {
            const emitter = this.pixiMappings.emitters[id];
            emitter.destroy();
        }

        // Destroy all the layers
        for (const id in this.pixiMappings.layers)
        {
            const layer = this.pixiMappings.layers[id];
            layer.destroy(true);
        }

        // Remove the current mappings
        this.pixiMappings = {
            layers: {},
            elements: {},
            emitters: {},
            emitterListeners: {},
        };

        // Add all the layers back
        if (this.pixiFront)
        {
            this.addPixiLayers(this.pixiFront, 'front');
        }

        if (this.pixiBack)
        {
            this.addPixiLayers(this.pixiBack, 'back');
        }
    }

    /**
     * De-initialises pixi instances
     */

    private deinitPixi()
    {
        if (this.pixiFront)
        {
            this.pixiFront.destroy(true);
        }

        if (this.pixiBack)
        {
            this.pixiBack.destroy(true);
        }
    }

    /**
     * Adds the layers to the pixi app
     *
     * @param pixi
     * @param side
     */

    private addPixiLayers(pixi: PIXI.Application, side: string)
    {
        for (const layer of this.page.layers)
        {
            if (layer.side !== side)
            {
                continue;
            }

            // Create the layer
            const pixiLayer = new PIXI.Container();
            pixiLayer.x = 0;
            pixiLayer.y = 0;

            pixi.stage.addChild(pixiLayer);

            // Save the mapping
            this.pixiMappings.layers[layer.id] = pixiLayer;

            // Add layer elements
            for (const element of layer.elements)
            {
                this.addPixiLayerElement(
                    pixi, pixiLayer, element,
                );
            }
        }
    }

    /**
     * Adds an element to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private addPixiLayerElement(
        pixi: PIXI.Application, layer: PIXI.Container, element: Element,
    )
    {
        switch (element.type)
        {
        case 'effect':
            this.addPixiLayerEffect(
                pixi, layer, element,
            );
            break;

        case 'image':
            this.addPixiLayerImage(
                pixi, layer, element,
            );
            break;

        case 'text':
            this.addPixiLayerText(
                pixi, layer, element,
            );
            break;
        }
    }

    /**
     * Adds an effect to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private async addPixiLayerEffect(
        pixi: PIXI.Application, layer: PIXI.Container, element: Element,
    )
    {
        if (!element.config.effect)
        {
            return;
        }

        const container = new PIXI.Container();
        container.x = this.getElementX(element);
        container.y = this.getElementY(element);

        layer.addChild(container);

        switch (element.config.effect.id)
        {
        case 'particle/nature/petals':
            this.addPixiLayerParticleEffect(
                pixi, container, element, new PetalsEffect(),
            );
            break;

        case 'particle/smoke/chimney':
            this.addPixiLayerParticleEffect(
                pixi, container, element, new ChimneySmokeEffect(),
            );
            break;

        case 'particle/weather/snow':
            this.addPixiLayerParticleEffect(
                pixi, container, element, new SnowEffect(),
            );
            break;

        case 'particle/weather/snow-thick':
            this.addPixiLayerParticleEffect(
                pixi, container, element, new SnowThickEffect(),
            );
            break;

        default:
        }
    }

    /**
     * Adds a particle effect to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     * @param effect
     */

    private async addPixiLayerParticleEffect(
        pixi: PIXI.Application, layer: PIXI.Container, element: Element, effect: ParticleEffectInterface,
    )
    {
        // Load textures
        // const textures: PIXI.Texture[] = [];
        // for (const path of effect.getParticleImages())
        // {
        //     textures.push(PIXI.Texture.from(path));
        // }

        // Load emitter
        const emitter = new particles.Emitter(layer, effect.getParticlesConfig(this.pageWidth, this.pageHeight));

        this.pixiMappings.emitters[element.id] = emitter;
        this.pixiMappings.emitterListeners[element.id] = () =>
        {
            emitter.update(PIXI.Ticker.shared.elapsedMS * 0.001);
        };

        // Start emitting
        emitter.emit = true;

        // Pre-warm
        const preWarmTime = effect.getPreWarmTime();
        if (preWarmTime > 0)
        {
            emitter.update(preWarmTime);
        }

        // Update with the pixi rendering
        pixi.ticker.add(this.pixiMappings.emitterListeners[element.id]);
    }

    /**
     * Adds an image to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private async addPixiLayerImage(
        pixi: PIXI.Application, layer: PIXI.Container, element: Element,
    )
    {
        if (!element.asset?.path)
        {
            return;
        }

        // Load the sprite
        const sprite = await this.loadSprite(element.asset);

        // Set position / size
        const x1 = this.getElementX(element);
        const y1 = this.getElementY(element);
        const maxWidth = this.getElementWidth(element);
        const maxHeight = this.getElementHeight(element);

        let fit: {width: number, height: number};

        switch (element.config.fit)
        {
        case 'contain':

            fit = MathsHelper.containFit(
                sprite.texture.width, sprite.texture.height, maxWidth, maxHeight,
            );

            sprite.x = x1 + ((maxWidth - fit.width) / 2);
            sprite.y = y1 + ((maxHeight - fit.height) / 2);
            sprite.width = fit.width;
            sprite.height = fit.height;

            break;

        case 'cover':

            fit = MathsHelper.coverFit(
                sprite.texture.width, sprite.texture.height, maxWidth, maxHeight,
            );

            sprite.x = x1 + ((maxWidth - fit.width) / 2);
            sprite.y = y1 + ((maxHeight - fit.height) / 2);
            sprite.width = fit.width;
            sprite.height = fit.height;

            break;

        case 'fill':
        default:

            sprite.x = x1;
            sprite.y = y1;
            sprite.width = maxWidth;
            sprite.height = maxHeight;
        }

        // Add to the layer
        layer.addChild(sprite);
    }

    /**
     * Adds text to a pixi layer
     *
     * @param pixi
     * @param layer
     * @param element
     */

    private async addPixiLayerText(
        pixi: PIXI.Application, layer: PIXI.Container, element: Element,
    )
    {
        if (!element.config.text)
        {
            return;
        }

        const width = this.getElementWidth(element);
        const height = this.getElementHeight(element);

        // Add a container for the text to which the text can be positioned within
        const container = new PIXI.Container();
        container.x = this.getElementX(element);
        container.y = this.getElementY(element);

        layer.addChild(container);

        // Add a background colour if required
        if (element.config.background?.colour)
        {
            const background = new PIXI.Graphics();
            background.x = 0;
            background.y = 0;
            background.beginFill(this.getPixiColour(element.config.background.colour, false));
            background.drawRect(
                0, 0, width, height,
            );
            background.alpha = this.getPixiAlpha(element.config.background.colour);

            container.addChild(background);
        }

        // Load the font
        let fontFamily: string;
        if (element.config.text.font)
        {
            fontFamily = element.config.text.font;

            // Ensure the font is loaded
            if (![ 'Arial', 'Verdana' ].includes(fontFamily))
            {
                const font = new FontFaceObserver.default(element.config.text.font);
                await font.load();
            }
        }
        else
        {
            fontFamily = 'Arial';
        }

        // Add the text
        const text = new PIXI.Text(element.config.text.text, {
            fontFamily,
            fontSize: this.getElementTextSize(element),
            fill: this.getPixiColour(element.config.text.colour),
            align: this.getElementTextAlign(element),
            wordWrap: true,
            wordWrapWidth: width,
            padding: 14,
            dropShadow: element.config.text.dropShadow,
            dropShadowColor: element.config.text.dropShadowColour,
            dropShadowBlur: element.config.text.dropShadowBlur,
            dropShadowDistance: element.config.text.dropShadowDistance,
        });

        // Set the text's position
        this.setPixiTextPosition(text, element);

        // Add to the container
        container.addChild(text);
    }

    /**
     * Sets the position of a Pixi text element
     *
     * @param text
     * @param element
     */

    private setPixiTextPosition(text: PIXI.Text, element: Element)
    {
        const width = this.getElementWidth(element);
        const height = this.getElementHeight(element);

        let anchorX: number;
        let anchorY: number;
        let x: number;
        let y: number;

        switch (element.config.align.horizontal)
        {
        case 'left':
            anchorX = 0;
            x = 0;
            break;

        case 'right':
            anchorX = 1;
            x = width;
            break;

        case 'centre':
        default:
            anchorX = 0.5;
            x = width / 2;
            break;
        }

        switch (element.config.align.vertical)
        {
        case 'top':
            anchorY = 1;
            y = height;
            break;

        case 'bottom':
            anchorY = 0;
            y = 0;
            break;

        case 'middle':
        default:
            anchorY = 0.5;
            y = height / 2;
            break;
        }

        text.anchor.set(anchorX, anchorY);
        text.x = x;
        text.y = y;
    }

    /**
     * Loads a sprite from an asset
     *
     * @param path
     */

    private async loadSprite(asset: Asset): Promise<PIXI.Sprite>
    {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const path = AssetService.getAssetFileUrl(asset);
        const texture = PIXI.Texture.from(path);
        const sprite = new PIXI.Sprite(texture);

        // Wait for the texture to load before returning
        if (texture.baseTexture.valid)
        {
            return sprite;
        }

        return new Promise((resolve, reject) =>
        {
            texture.addListener('update', () =>
            {
                resolve(sprite);
            });
        });
    }

    /**
     * Returns the actual x of the element based on the canvas size
     *
     * @param element
     */

    private getElementX(element: Element): number
    {
        return new Decimal(element.config.position.x1)
            .div(100)
            .mul(this.pageWidth)
            .toNumber();
    }

    /**
     * Returns the actual y of the element based on the canvas size
     *
     * @param element
     */

    private getElementY(element: Element): number
    {
        return new Decimal(element.config.position.y1)
            .div(100)
            .mul(this.pageHeight)
            .toNumber();
    }

    /**
     * Returns the actual width of the element based on the canvas size
     *
     * @param element
     */

    private getElementWidth(element: Element): number
    {
        return new Decimal(element.config.position.x2).minus(element.config.position.x1)
            .div(100)
            .mul(this.pageWidth)
            .toNumber();
    }

    /**
     * Returns the actual height of the element based on the canvas size
     *
     * @param element
     */

    private getElementHeight(element: Element): number
    {
        return new Decimal(element.config.position.y2).minus(element.config.position.y1)
            .div(100)
            .mul(this.pageHeight)
            .toNumber();
    }

    /**
     * Returns the text size for an element
     *
     * @param element
     * @returns
     */

    private getElementTextSize(element: Element): number
    {
        const percentage: number = element.config.text?.size ? new Decimal(element.config.text.size).toNumber() : 5;

        return new Decimal(this.pageHeight).div(100)
            .mul(percentage)
            .toNumber();
    }

    /**
     * Returns the text alignmnent for an element
     *
     * @param element
     */

    private getElementTextAlign(element: Element): PIXI.TextStyleAlign
    {
        switch (element.config.align.horizontal)
        {
        case 'centre':
            return 'center';

        case 'left':
            return 'left';

        case 'right':
            return 'right';

        default:
            return 'center';
        }
    }

    /**
     * Returns a Pixi compatible colour from a HTML colour
     *
     * @param htmlColour
     * @param includeAlpha
     * @returns
     */

    private getPixiColour(htmlColour: string|null, includeAlpha = true): number
    {
        if (!htmlColour)
        {
            return 0x000000;
        }

        if (includeAlpha)
        {
            return PIXI.utils.string2hex(htmlColour);
        }
        else
        {
            return PIXI.utils.string2hex(htmlColour.substr(0, 7));
        }
    }

    /**
     * Returns a pixi compatible alpha value from a HTML colour
     *
     * @param htmlColour
     */

    private getPixiAlpha(htmlColour: string|null): number
    {
        if (!htmlColour)
        {
            return 1;
        }

        if (htmlColour.length === 7)
        {
            return 1;
        }
        else
        {
            const hexAlpha = htmlColour.substr(7, 2);
            const intAlpha = parseInt(hexAlpha, 16);

            const alpha = new Decimal(intAlpha).div(255)
                .toNumber();

            return alpha;
        }
    }

    /**
     * Handles side click action
     *
     * @param side
     */

    private onSideClicked(side: string)
    {
        this.$emit('side-clicked', side);
    }
}