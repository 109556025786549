import AbstractTask from '../abstract-task';
import Asset from '@/model/entity/asset/asset';
import Exception from '@/model/exception/exception';
import GetAssetTask from './get-asset';
import { Method } from 'axios';

export default class PostAssetTask extends AbstractTask
{
    public asset!: Asset;

    public async run(): Promise<Asset>
    {
        const response = await this.execute();

        if (response.status === 201)
        {
            const idParts = response.data.split('/');
            const id = idParts[idParts.length - 1];

            const task = new GetAssetTask();
            task.id = id;

            return task.run();
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/assets';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|FormData|null
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const entityData: {[key: string]: any} = Object.assign({}, this.asset.dto);
        delete entityData.id;
        delete entityData.file;

        const data = new FormData();

        // Add the base data
        data.append('data', JSON.stringify(entityData));

        // Add the file
        if (this.asset.file)
        {
            data.append('file', this.asset.file);
        }

        return data;
    }
}