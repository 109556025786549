import { Component, Prop, Vue } from 'vue-property-decorator';

import ElementComponent from '../element/Element.vue';
import Layer from '@/model/entity/card/layer';

@Component({
    components:
    {
        'app-element': ElementComponent,
    },
})
export default class LayerComponent extends Vue
{
    @Prop()
    private layer!: Layer;

    @Prop()
    private templateOverrides!: any;

    @Prop()
    private pageWidth!: number;

    @Prop()
    private pageHeight!: number;
}