/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import User from '@/model/entity/organisation/user';

export default {
    namespaced: true,
    state: (): any => ({
        refreshingToken: false,
        refreshToken: null,
        token: null,
        user: null,
    }),
    mutations: {
        refreshingToken(state: any, refreshingToken: boolean): void
        {
            state.refreshingToken = refreshingToken;
        },
        refreshToken(state: any, refreshToken: string): void
        {
            state.refreshToken = refreshToken;
        },
        token(state: any, token: string): void
        {
            state.token = token;
        },
        user(state: any, user: User|null): void
        {
            state.user = user ? Object.assign({}, user.dto) : null;
        },
    },
    actions: {},
    getters: {
        refreshingToken(state: any): boolean
        {
            return state.refreshingToken;
        },
        refreshToken(state: any): string|null
        {
            return state.refreshToken;
        },
        token(state: any): string|null
        {
            return state.token;
        },
        user(state: any): User|null
        {
            if (!state.user)
            {
                return null;
            }

            return new User(state.user);
        },
    },
};