import ElementDto from './element-dto';
import PageDto from './page-dto';

export default class LayerDto
{
    id = '';
    page: PageDto|null = null;
    side = 'front';
    zIndex = 1;
    dateCreated = '';
    dateUpdated = '';
    elements: ElementDto[] = [];
}