import CategoryDto from './category-dto';

export default class Category
{
    dto!: CategoryDto;

    public constructor(dto: CategoryDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get parent(): Category|null
    {
        if (!this.dto.parent)
        {
            return null;
        }

        return new Category(this.dto.parent);
    }

    public set parent(parent: Category|null)
    {
        if (!parent)
        {
            this.dto.parent = null;
            return;
        }

        this.dto.parent = Object.assign({}, parent.dto);
    }

    public get slug(): string
    {
        return this.dto.slug;
    }

    public set slug(slug: string)
    {
        this.dto.slug = slug;
    }

    public get name(): string
    {
        return this.dto.name;
    }

    public set name(name: string)
    {
        this.dto.name = name;
    }

    public get icon(): string
    {
        return this.dto.icon;
    }

    public set icon(icon: string)
    {
        this.dto.icon = icon;
    }

    public get isNavigation(): boolean
    {
        return this.dto.isNavigation;
    }

    public set isNavigation(isNavigation: boolean)
    {
        this.dto.isNavigation = isNavigation;
    }

    public get navigationPosition(): number
    {
        return this.dto.navigationPosition;
    }

    public set navigationPosition(navigationPosition: number)
    {
        this.dto.navigationPosition = navigationPosition;
    }
}