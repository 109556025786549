import AbstractAuthenticatedService from '../abstract-authenticated-service';
import Category from '@/model/entity/taxonomy/category';
import CategorySearchConditionsInterface from '@/model/interface/taxonomy/category-search-conditions-interface';
import GetCategoriesTask from '@/model/task/taxonomy/get-categories-task';
import GetCategoryTask from '@/model/task/taxonomy/get-category-task';

export default class CategoryService extends AbstractAuthenticatedService
{
    /**
     * Finds matching templates
     *
     * @param searchConditions
     */

    public async findCategories(searchConditions: CategorySearchConditionsInterface|null): Promise<Category[]>
    {
        const task = new GetCategoriesTask();

        if (searchConditions)
        {
            task.searchConditions = searchConditions;
        }

        return task.run();
    }

    /**
     * Loads the categories for navigation
     */

    public async loadNavigationCategories(): Promise<Category[]>
    {
        const task = new GetCategoriesTask();

        return task.run();
    }

    /**
     * Loads a category by id
     *
     * @param id
     */

    public async loadCategory(id: string): Promise<Category>
    {
        const task = new GetCategoryTask();
        task.id = id;

        return task.run();
    }

    /**
     * Loads a category by slug
     *
     * @param slug
     */

    public async loadCategoryBySlug(slug: string): Promise<Category>
    {
        return this.loadCategory(slug);
    }
}