import AssetDto from '../asset/asset-dto';
import ElementConfigInterface from '@/model/interface/card/element-config-interface';
import LayerDto from './layer-dto';

export default class ElementDto
{
    id = '';
    layer: LayerDto|null = null;
    type = 'text';
    asset: AssetDto|null = null;

    config: ElementConfigInterface = {
        position: {
            x1: '0.00',
            y1: '0.00',
            x2: '100.00',
            y2: '100.00',
        },
        align: {
            horizontal: 'centre',
            vertical: 'middle',
        },
        fit: 'contain',
    };

    dateCreated = '';
    dateUpdated = '';
}