import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Asset from '@/model/entity/asset/asset';
import AssetDto from '@/model/entity/asset/asset-dto';
import AssetForm from '../asset-form/AssetForm.vue';
import AssetSearchResultsInterface from '@/model/interface/asset/asset-search-results-interface';
import AssetService from '@/model/service/asset/asset-service';

@Component({
    components:
    {
        'app-asset-form': AssetForm,
    },
})
export default class MediaSelectorComponent extends Vue
{
    @Prop({
        type: Object,
        default: null,
    })
    private value!: Asset|null;

    @Prop({
        type: String,
        default: 'image',
    })
    private type!: string;

    private selectorDialog = false;
    private uploadDialog = false;

    private asset: Asset|null = null;
    private selectedAsset: Asset|null = null;

    private dropping = false;
    private newAsset: Asset|null = null;

    private searchTerm = '';
    private page = 1;
    private itemsPerPage = 20;
    private results: AssetSearchResultsInterface = {
        results: [],
        totalResults: 0,
        page: 1,
        totalPages: 1,
        firstPage: null,
        lastPage: null,
        nextPage: null,
        previousPage: null,
    };

    private created(): void
    {
        this.asset = this.value;
        this.selectedAsset = this.asset;

        this.loadAssets();
    }

    private get fileUrl(): string|null
    {
        return this.getAssetFileUrl(this.asset);
    }

    private getAssetFileUrl(asset: Asset|null): string|null
    {
        return asset ? AssetService.getAssetFileUrl(asset) : null;
    }

    @Watch('value')
    private onValueChanged()
    {
        this.asset = this.value;
        this.selectedAsset = this.asset;
    }

    @Watch('searchTerm')
    private onSearchTermChanged()
    {
        this.loadAssets();
    }

    private onUpdate()
    {
        this.$emit('input', this.asset);
    }

    private async loadAssets()
    {
        this.results = await this.$serviceContainer.assetService.findAssets({
            title: this.searchTerm,
            page: 1,
            itemsPerPage: this.itemsPerPage,
            order: [
                {
                    key: 'title',
                    descending: false,
                },
            ],
        });
    }

    /**
     * Handles file drag events over the selector
     *
     * @param event
     */

    private onDragOver(event: DragEvent)
    {
        event.preventDefault();

        this.dropping = true;
    }

    /**
     * Handles file drag events out of the selector
     *
     * @param event
     */

    private onDragLeave(event: DragEvent)
    {
        event.preventDefault();

        this.dropping = false;
    }

    /**
     * Handles files being dropped on the selector
     *
     * @param event
     * @returns
     */

    private async onDrop(event: DragEvent)
    {
        event.preventDefault();

        this.dropping = false;

        if (!event.dataTransfer?.files)
        {
            return;
        }

        this.newAsset = new Asset(new AssetDto());
        this.newAsset.title = 'New file';
        this.newAsset.licenceType = 'attribution';
        this.newAsset.file = event.dataTransfer.files[0];

        this.uploadDialog = true;
    }

    /**
     * Handles cancelling file upload
     */

    private onCancelUpload()
    {
        this.uploadDialog = false;
    }

    /**
     * Handles upload confirmation
     */

    private async onUpload()
    {
        if (!this.newAsset)
        {
            return;
        }

        await this.$serviceContainer.assetService.createAsset(this.newAsset);
        this.loadAssets();

        this.uploadDialog = false;
        this.newAsset = null;
    }

    /**
     * Handles asset clicks
     *
     * @param asset
     */

    private onAssetClicked(asset: Asset)
    {
        // Update the selected asset
        this.selectedAsset = asset;
    }

    /**
     * Handles cancel button
     */

    private onCancel()
    {
        // Close the dialog
        this.selectorDialog = false;

        // Reset the working asset
        this.asset = this.value;
        this.selectedAsset = this.asset;
    }

    /**
     * Handles confirmation of the selected asset
     */

    private onSelect()
    {
        // Update the working asset
        this.asset = this.selectedAsset;

        // Trigger an update
        this.onUpdate();

        // Close the dialog
        this.selectorDialog = false;
    }
}