import AbstractTask from '../abstract-task';
import { Method } from 'axios';

export default class ForgottenPasswordTask extends AbstractTask
{
    public email = '';

    public async run(): Promise<boolean>
    {
        const response = await this.execute();

        if (response.status === 202)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    protected get url(): string
    {
        return '/users/forgotten-password';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    protected get data(): {[key: string]: string}
    {
        return {
            email: this.email,
        };
    }
}