import { Component, Vue } from 'vue-property-decorator';

import Template from '@/model/entity/card/template';
import TemplateDto from '@/model/entity/card/template-dto';
import TemplateEditor from '@/components/template-editor/TemplateEditor.vue';

@Component({
    components:
    {
        'app-template-editor': TemplateEditor,
    },
})
export default class AdminTemplateCreate extends Vue
{
    private template: Template = new Template(new TemplateDto());

    /**
     * Handles saved events
     */

    private onSaved()
    {
        this.$router.replace({
            name: 'admin-template-edit',
            params:
            {
                slug: this.template.slug,
            },
        });
    }
}