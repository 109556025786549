enum Exception
{
    AssetNotFound = 'AssetNotFound',
    CardNotFound = 'CardNotFound',
    CategoryNotFound = 'CategoryNotFound',
    InvalidDetails = 'InvalidDetails',
    NotLoggedIn = 'NotLoggedIn',
    RefreshTokenExpired = 'RefreshTokenExpired',
    TemplateNotFound = 'TemplateNotFound',
    TemplatePageNotFound = 'TemplatePageNotFound',
    TemplateLayerNotFound = 'TemplateLayerNotFound',
    TemplateElementNotFound = 'TemplateElementNotFound',
    TokenExpired = 'TokenExpired',
    UnknownError = 'UnknownError',
    UserNotFound = 'UserNotFound'
}

export default Exception;