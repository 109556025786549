import * as particles from '@pixi/particle-emitter';

import ParticleEffectInterface from '../particle-effect-interface';

export default class ChimneyEffect implements ParticleEffectInterface
{
    // Variables based on a 700px height card
    private static PARTICLE_START_SCALE = 0.2 / 700;
    private static PARTICLE_END_SCALE = 0.5 / 700;
    private static PARTICLE_START_SPEED = 30 / 700;
    private static PARTICLE_MID_SPEED = 25 / 700;
    private static PARTICLE_END_SPEED = 5 / 700;

    public getParticleImages(): string[]
    {
        return [ require('@/assets/images/particles/smoke/smoke.png') ];
    }

    public getParticlesConfig(width: number, height: number): particles.EmitterConfigV3
    {
        // Calculate speeds
        const startSpeed = ChimneyEffect.PARTICLE_START_SPEED * height;
        const midSpeed = ChimneyEffect.PARTICLE_MID_SPEED * height;
        const endSpeed = ChimneyEffect.PARTICLE_END_SPEED * height;

        // Calculate scales
        const startScale = ChimneyEffect.PARTICLE_START_SCALE * height;
        const endScale = ChimneyEffect.PARTICLE_END_SCALE * height;

        return {
            behaviors: [
                {
                    type: 'alpha',
                    config: {
                        alpha: {
                            list: [
                                {
                                    value: 0.3,
                                    time: 0,
                                },
                                {
                                    value: 0.2,
                                    time: 0.5,
                                },
                                {
                                    value: 0.1,
                                    time: 0.7,
                                },
                                {
                                    value: 0,
                                    time: 1,
                                },
                            ],
                            isStepped: false,
                        },
                    },
                },
                {
                    type: 'color',
                    config: {
                        color: {
                            list: [
                                {
                                    value: 'ffffff',
                                    time: 0,
                                },
                                {
                                    value: 'eaeaea',
                                    time: 1,
                                },
                            ],
                            isStepped: false,
                        },
                    },
                },
                {
                    type: 'scale',
                    config: {
                        scale: {
                            list: [
                                {
                                    value: startScale,
                                    time: 0,
                                },
                                {
                                    value: endScale,
                                    time: 1,
                                },
                            ],
                            isStepped: false,
                        },
                        minMult: 1,
                    },
                },
                {
                    type: 'moveSpeed',
                    config: {
                        speed: {
                            list: [
                                {
                                    value: startSpeed,
                                    time: 0,
                                },
                                {
                                    value: midSpeed,
                                    time: 0.6,
                                },
                                {
                                    value: endSpeed,
                                    time: 1,
                                },
                            ],
                            isStepped: true,
                        },
                        minMult: 1,
                    },
                },
                {
                    type: 'rotation',
                    config: {
                        minSpeed: 50,
                        maxSpeed: 50,
                        minStart: 255,
                        maxStart: 285,
                    },
                },
                {
                    type: 'spawnShape',
                    config: {
                        type: 'torus',
                        data: {
                            x: 0,
                            y: 0,
                            radius: 2,
                            innerRadius: 0,
                            affectRotation: false,
                        },
                    },
                },
                {
                    type: 'textureSingle',
                    config: {
                        texture: require('@/assets/images/particles/smoke/smoke.png'),
                    },
                },
            ],
            lifetime: {
                min: 8,
                max: 12,
            },
            frequency: 0.2,
            maxParticles: 500,
            pos: {
                x: 0,
                y: 0,
            },
        };
    }

    public getPreWarmTime(): number
    {
        return 10;
    }
}