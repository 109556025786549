import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Layer from '@/model/entity/card/layer';
import TemplateLayerItem from '@/model/interface/card/template-layer-item';
import { find } from 'lodash';

@Component
export default class PageLayersComponent extends Vue
{
    @Prop()
    private value!: Layer[];

    private layers: Layer[] = [];
    private open: string[] = [];

    private activeItemId: string|null = null;

    private created()
    {
        this.layers = [ ...this.value ];
    }

    @Watch('value')
    private onValueChanged()
    {
        this.layers = [ ...this.value ];
    }

    /**
     * Returns the treeview items
     */

    private get items(): TemplateLayerItem[]
    {
        const items: TemplateLayerItem[] = [];
        this.open = [];

        for (const layer of this.layers)
        {
            // Get elements as children
            const children: TemplateLayerItem[] = [];

            for (const element of layer.elements)
            {
                children.push({
                    id: element.id,
                    name: element.type,
                });

                this.open.push(element.id);
            }

            items.push({
                id: layer.id,
                name: layer.zIndex.toString(),
                children,
            });

            this.open.push(layer.id);
        }

        return items;
    }

    /**
     * Handles item activations
     *
     * @param ids
     */

    private onItemActivated(ids: string[])
    {
        if (ids.length === 0 && this.activeItemId)
        {
            if (this.itemIsLayer(this.activeItemId))
            {
                this.$emit('layer-activated', null);
            }
            else
            {
                this.$emit('element-activated', null);
            }

            this.activeItemId = null;

            return;
        }

        // Get the single activated id
        const id = ids[0];

        if (this.itemIsLayer(id))
        {
            this.$emit('layer-activated', id);

            if (this.activeItemId && !this.itemIsLayer(this.activeItemId))
            {
                this.$emit('element-activated', null);
            }
        }
        else
        {
            this.$emit('element-activated', id);

            if (this.activeItemId && this.itemIsLayer(this.activeItemId))
            {
                this.$emit('layer-activated', null);
            }
        }

        this.activeItemId = id;
    }

    /**
     * Handles add layer requests
     */

    private onAddLayer()
    {
        this.$emit('add-layer');
    }

    /**
     * Handles element creation requests
     *
     * @param layerId
     * @param type
     */

    private onAddElement(layerId: string, type: string)
    {
        this.$emit(
            'add-element', layerId, type,
        );
    }

    /**
     * Handles layer move requests
     *
     * @param layerId
     */

    private onMoveUp(layerId: string)
    {
        this.$emit(
            'move-layer', layerId, 'up',
        );
    }

    /**
     * Handles layer move requests
     *
     * @param layerId
     */

    private onMoveDown(layerId: string)
    {
        this.$emit(
            'move-layer', layerId, 'down',
        );
    }

    /**
     * Handles item deletion requests
     *
     * @param id
     */

    private onDeleteItem(id: string)
    {
        if (this.itemIsLayer(id))
        {
            this.onDeleteLayer(id);
        }
        else
        {
            this.onDeleteElement(id);
        }

        if (id === this.activeItemId)
        {
            this.activeItemId = null;
        }
    }

    /**
     * Handles delete layer requests
     *
     * @param id
     */

    private onDeleteLayer(id: string)
    {
        this.$emit('delete-layer', id);
    }

    /**
     * Handles delete element requests
     *
     * @param id
     */

    private onDeleteElement(id: string)
    {
        this.$emit('delete-element', id);
    }

    /**
     * Returns whether the given item is a layer
     *
     * @param id
     * @returns
     */

    private itemIsLayer(id: string)
    {
        return !!find(this.layers, { id });
    }
}