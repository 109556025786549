import AbstractAuthenticatedService from '../abstract-authenticated-service';
import GetTagsTask from '@/model/task/taxonomy/get-tags-task';
import Tag from '@/model/entity/taxonomy/tag';
import TagSearchConditionsInterface from '@/model/interface/taxonomy/tag-search-conditions-interface';

export default class TagService extends AbstractAuthenticatedService
{
    /**
     * Finds matching tags
     *
     * @param searchConditions
     */

    public async findTags(searchConditions: TagSearchConditionsInterface|null): Promise<Tag[]>
    {
        const task = new GetTagsTask();

        if (searchConditions)
        {
            task.searchConditions = searchConditions;
        }

        return task.run();
    }
}