import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class EffectSelectorComponent extends Vue
{
    @Prop()
    private value!: string;

    private effect!: string;

    private created(): void
    {
        this.effect = this.value;
    }

    @Watch('value')
    private onValueChanged()
    {
        this.effect = this.value;
    }

    private onUpdate()
    {
        this.$emit('input', this.effect);
    }
}