import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import Template from '@/model/entity/card/template';

export default class GetTemplateTask extends AbstractTask
{
    public id = '';

    public async run(): Promise<Template>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Template(response.data);
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.TemplateNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/templates/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }
}