import { find, findIndex } from 'lodash';

import Layer from './layer';
import LayerDto from './layer-dto';
import PageDto from './page-dto';
import Template from './template';
import { v4 as uuidv4 } from 'uuid';

export default class Page
{
    dto!: PageDto;

    public constructor(dto: PageDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get template(): Template|null
    {
        if (!this.dto.template)
        {
            return null;
        }

        return new Template(this.dto.template);
    }

    public set template(template: Template|null)
    {
        if (!template)
        {
            this.dto.template = null;
            return;
        }

        this.dto.template = Object.assign({}, template.dto);
    }

    public get position(): number
    {
        return this.dto.position;
    }

    public set position(position: number)
    {
        this.dto.position = position;
    }

    public get dateCreated(): string
    {
        return this.dto.dateCreated;
    }

    public set dateCreated(dateCreated: string)
    {
        this.dto.dateCreated = dateCreated;
    }

    public get dateUpdated(): string
    {
        return this.dto.dateUpdated;
    }

    public set dateUpdated(dateUpdated: string)
    {
        this.dto.dateUpdated = dateUpdated;
    }

    public get layers(): Layer[]
    {
        const layers: Layer[] = [];

        for (const layerDto of this.dto.layers)
        {
            layers.push(new Layer(layerDto));
        }

        return layers;
    }

    public set layers(layers: Layer[])
    {
        this.dto.layers = [];

        for (const layer of layers)
        {
            this.dto.layers.push(layer.dto);
        }
    }

    /**
     * Returns the layers for the given side of the page
     */

    public getSideLayers(side: string): Layer[]
    {
        const layers: Layer[] = [];

        for (const layer of this.layers)
        {
            if (layer.side !== side)
            {
                continue;
            }

            layers.push(layer);
        }

        return layers;
    }

    /**
     * Adds a new empty layer on the given side of the page
     *
     * @param side
     * @returns
     */

    public createLayer(side: string): Layer
    {
        // Create a new empty layer
        const layer = new Layer(new LayerDto());
        layer.id = uuidv4();
        layer.page = new Page(this.dto);
        layer.side = side;
        layer.zIndex = this.getSideLayers(side).length;

        // Add to the array of layers
        this.layers = [ ...this.layers, layer ];
        this.reorderLayers();

        // Return the new layer
        return layer;
    }

    /**
     * Updates the given layer in the page
     *
     * @param layer
     * @returns
     */

    public updateLayer(layer: Layer): void
    {
        // Get the layer's index in the page
        const index = findIndex(this.layers, { id: layer.id });
        if (index < 0)
        {
            return;
        }

        // Update the layer in the page
        const layers = [ ...this.layers ];
        layers.splice(
            index, 1, layer,
        );

        this.layers = layers;
    }

    public moveLayer(id: string, direction: string)
    {
        // Get the layer's index in the page
        const index = findIndex(this.layers, { id });
        if (index < 0)
        {
            return;
        }

        // Remove the layer from the array
        const layers = [ ...this.layers ];
        const removed = layers.splice(index, 1);

        // Calculate the new index position
        let newIndex: number;

        if (direction === 'up')
        {
            newIndex = index > 0 ? index - 1 : index;
        }
        else
        {
            newIndex = index < this.layers.length - 1 ? index + 1 : index;
        }

        // Add the layer into the new position
        layers.splice(
            newIndex, 0, removed[0],
        );

        // Update the layers
        this.layers = layers;
        this.reorderLayers();
    }

    /**
     * Deletes a layer from the page
     *
     * @param id
     * @returns
     */

    public deleteLayer(id: string): Layer|null
    {
        const index = findIndex(this.layers, { id });
        if (index < 0)
        {
            return null;
        }

        const layers = [ ...this.layers ];
        const removed = layers.splice(index, 1);

        this.layers = layers;
        this.reorderLayers();

        return removed[0];
    }

    /**
     * Returns the layer with the given id
     *
     * @param id
     * @returns
     */

    public getLayer(id: string|undefined): Layer|null
    {
        const layer = find(this.layers, { id });

        return layer ?? null;
    }

    /**
     * Re-orders the layers to ensure they are sequential
     */

    private reorderLayers()
    {
        let zIndex = 0;

        for (const layer of this.layers)
        {
            layer.zIndex = zIndex;
            zIndex++;
            this.updateLayer(layer);
        }
    }
}