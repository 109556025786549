export default class AssetDto
{
    id = '';
    type = 'image';
    title = '';
    originalFilename = '';
    path = '';
    size = 0;
    licenceType = '';
    licenceDetails = '';
    licenceAttribution: string|null = null;
    dateCreated = '';
    dateUpdated = '';

    file: Blob|null = null;
}