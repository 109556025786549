import AbstractSearchTask from '../abstract-search-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';
import ParamsInterface from '@/model/interface/search/params-interface';
import Template from '@/model/entity/card/template';
import TemplateDto from '@/model/entity/card/template-dto';
import TemplateSearchConditionsInterface from '@/model/interface/card/template-search-conditions-interface';
import TemplateSearchResultsInterface from '@/model/interface/card/template-search-results-interface';

export default class GetTemplatesTask extends AbstractSearchTask
{
    public searchConditions?: TemplateSearchConditionsInterface;

    public async run(): Promise<TemplateSearchResultsInterface>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const searchResults = this.parseSearchResults(response);

            const templates: Template[] = [];
            for (const templateDto of searchResults.results)
            {
                templates.push(new Template(templateDto as TemplateDto));
            }

            searchResults.results = templates;

            return searchResults as TemplateSearchResultsInterface;
        }

        switch (response.status)
        {
        case 401:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/templates';
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    protected get params(): ParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}