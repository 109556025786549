import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Asset from '@/model/entity/asset/asset';
import TagSelector from '../tag-selector/TagSelector.vue';

@Component({
    components:
    {
        'app-tag-selector': TagSelector,
    },
})
export default class AssetFormComponent extends Vue
{
    @Prop()
    private value!: Asset;

    private asset!: Asset;
    private valid = false;

    private titleRules =
        [ (v: string) => !!v || this.$t('validation.title_required') ];

    private created()
    {
        this.asset = this.value;
    }

    @Watch('value')
    private onValueChanged()
    {
        this.asset = this.value;
    }

    /**
     * Handles value updates
     */

    private onUpdate()
    {
        this.$emit('input', this.asset);
    }
}