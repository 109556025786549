import { find, findIndex } from 'lodash';

import CategorisableInterface from '@/model/interface/taxonomy/categorisable-interface';
import Category from '../taxonomy/category';
import Page from './page';
import PageDto from './page-dto';
import Tag from '../taxonomy/tag';
import TaggableInterface from '@/model/interface/taxonomy/taggable-interface';
import TemplateDto from './template-dto';
import { v4 as uuidv4 } from 'uuid';

export default class Template implements CategorisableInterface, TaggableInterface
{
    dto!: TemplateDto;

    public constructor(dto: TemplateDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get slug(): string
    {
        return this.dto.slug;
    }

    public set slug(slug: string)
    {
        this.dto.slug = slug;
    }

    public get title(): string
    {
        return this.dto.title;
    }

    public set title(title: string)
    {
        this.dto.title = title;
    }

    public get description(): string|null
    {
        return this.dto.description;
    }

    public set description(description: string|null)
    {
        this.dto.description = description;
    }

    public get isPublic(): boolean
    {
        return this.dto.isPublic;
    }

    public set isPublic(isPublic: boolean)
    {
        this.dto.isPublic = isPublic;
    }

    public get dateCreated(): string
    {
        return this.dto.dateCreated;
    }

    public set dateCreated(dateCreated: string)
    {
        this.dto.dateCreated = dateCreated;
    }

    public get dateUpdated(): string
    {
        return this.dto.dateUpdated;
    }

    public set dateUpdated(dateUpdated: string)
    {
        this.dto.dateUpdated = dateUpdated;
    }

    public get pages(): Page[]
    {
        const pages: Page[] = [];

        for (const pageDto of this.dto.pages)
        {
            pages.push(new Page(pageDto));
        }

        return pages;
    }

    public set pages(pages: Page[])
    {
        this.dto.pages = [];

        for (const page of pages)
        {
            this.dto.pages.push(page.dto);
        }
    }

    /**
     * Adds a new empty page on the given side of the page
     *
     * @returns
     */

    public createPage(): Page
    {
        // Create a new empty page
        const page = new Page(new PageDto());
        page.id = uuidv4();
        page.template = new Template(this.dto);
        page.position = this.pages.length + 1;

        // Add to the array of pages
        this.pages = [ ...this.pages, page ];
        this.reorderPages();

        // Return the new page
        return page;
    }

    /**
     * Updates the given page in the template
     *
     * @param page
     * @returns
     */

    public updatePage(page: Page): void
    {
        // Get the page's index in the template
        const pageIndex = findIndex(this.pages, { id: page.id });
        if (pageIndex < 0)
        {
            return;
        }

        // Update the page in the template
        const pages = [ ...this.pages ];
        pages.splice(
            pageIndex, 1, page,
        );

        this.pages = pages;
    }

    /**
     * Deletes a page from the template
     *
     * @param id
     * @returns
     */

    public deletePage(id: string): Page|null
    {
        const index = findIndex(this.pages, { id });
        if (index < 0)
        {
            return null;
        }

        const pages = [ ...this.pages ];
        const removed = pages.splice(index, 1);

        this.pages = pages;
        this.reorderPages();

        return removed[0];
    }

    /**
     * Returns the page with the given id
     *
     * @param id
     * @returns
     */

    public getPage(id: string): Page|null
    {
        const page = find(this.pages, { id });

        return page ?? null;
    }

    /**
     * Re-orders the pages to ensure they are sequential
     */

    private reorderPages()
    {
        let position = 1;

        for (const page of this.pages)
        {
            page.position = position;
            position++;
            this.updatePage(page);
        }
    }

    public get categories(): Category[]
    {
        const categories: Category[] = [];

        for (const categoryDto of this.dto.categories)
        {
            categories.push(new Category(categoryDto));
        }

        return categories;
    }

    public set categories(categories: Category[])
    {
        this.dto.categories = [];

        for (const category of categories)
        {
            this.dto.categories.push(category.dto);
        }
    }

    public get categorySlugs(): string[]
    {
        const categories: string[] = [];

        for (const category of this.categories)
        {
            categories.push(category.slug);
        }

        categories.sort();

        return categories;
    }

    public get tags(): Tag[]|string[]
    {
        const tags: Tag[] = [];

        for (const tagDto of this.dto.tags)
        {
            tags.push(new Tag(tagDto));
        }

        return tags;
    }

    public set tags(tags: Tag[]|string[])
    {
        this.dto.tags = [];

        for (const tag of tags)
        {
            if (typeof tag === 'string')
            {
                this.dto.tags.push({
                    id: '',
                    title: tag.toLowerCase(),
                });
            }
            else
            {
                this.dto.tags.push(tag.dto);
            }
        }
    }

    public get tagTitles(): string[]
    {
        const tags: string[] = [];

        for (const tag of this.tags)
        {
            if (typeof tag === 'string')
            {
                tags.push(tag);
            }
            else
            {
                tags.push(tag.title);
            }
        }

        tags.sort();

        return tags;
    }
}