import AbstractTask from './abstract-task';
import { AxiosResponse } from 'axios';
import SearchResultsInterface from '../interface/search/search-results-interface';

export default abstract class AbstractSearchTask extends AbstractTask
{
    /**
     * Parses standard search results from a response
     *
     * @param response
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected parseSearchResults(response: AxiosResponse<any>): SearchResultsInterface
    {
        // Configure base properties
        const searchResults: SearchResultsInterface =
        {
            results: response.data['hydra:member'],
            totalResults: response.data['hydra:totalItems'],
            totalPages: 1,
            page: 1,
            firstPage: '',
            lastPage: '',
            previousPage: '',
            nextPage: '',
        };

        // Calculate total pages
        if (searchResults.results.length === searchResults.totalResults)
        {
            searchResults.totalPages = 1;
        }
        else
        {
            searchResults.totalPages = Math.ceil(searchResults.totalResults / searchResults.results.length);
        }

        // Get current page
        searchResults.page = response.config?.params?.page ? response.config?.params?.page : 1;

        if (response.data?.['hydra:view'])
        {
            const pagination = response.data['hydra:view'];

            // Get first page url
            if (pagination?.['hydra:first'])
            {
                searchResults.firstPage = pagination['hydra:first'];
            }
            else
            {
                searchResults.firstPage = response.config.url ?? null;
            }

            // Get last page url
            if (pagination?.['hydra:last'])
            {
                searchResults.lastPage = pagination['hydra:last'];
            }
            else
            {
                searchResults.lastPage = response.config.url ?? null;
            }

            // Get previous page url
            if (response.data?.['hydra:view']?.['hydra:previous'])
            {
                searchResults.previousPage = response.data['hydra:view']['hydra:previous'];
            }
            else
            {
                searchResults.previousPage = searchResults.lastPage;
            }

            // Get next page url
            if (response.data?.['hydra:view']?.['hydra:next'])
            {
                searchResults.nextPage = response.data['hydra:view']['hydra:next'];
            }
            else
            {
                searchResults.nextPage = searchResults.lastPage;
            }
        }

        // Return the results
        return searchResults;
    }
}