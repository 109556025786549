import '@/plugins/app';
import '@/styles/styles.scss';

import App from './app/App.vue';
import Particles from 'particles.vue';
import Vue from 'vue';
import VueDragResize from 'vue-drag-resize';
import VueResizeText from 'vue-resize-text';
import VueScreen from 'vue-screen';
import VueShowdownPlugin from 'vue-showdown';
import VueSimpleMarkdown from 'vue-simple-markdown';
import i18n from './i18n';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';

// Disabled until the issue with a white screen on service worker cache is fixed
// (the old JS file no longer exists so fails to load)
// Import './registerServiceWorker';


Vue.config.productionTip = false;

Vue.use(Particles);
Vue.use(VueResizeText);
Vue.use(VueSimpleMarkdown);
Vue.use(VueScreen);
Vue.use(VueShowdownPlugin, {
    flavor: 'github',
    options: {
        emoji: true,
        openLinksInNewWindow: true,
    },
});

Vue.component('vue-drag-resize', VueDragResize);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount('#app');