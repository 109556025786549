import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import Layer from '@/model/entity/card/layer';
import { Method } from 'axios';

export default class PostLayerTask extends AbstractTask
{
    public layer!: Layer;

    public async run(): Promise<Layer>
    {
        const response = await this.execute();

        if (response.status === 201)
        {
            return new Layer(response.data);
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return '/template_layers';
    }

    protected get method(): Method
    {
        return 'POST';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        if (!this.layer.page)
        {
            return null;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: {[key: string]: any} = Object.assign({}, this.layer.dto);
        delete data.id;
        data.page = `/api/template_pages/${ this.layer.page.id }`;

        return data;
    }
}