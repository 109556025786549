import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Category from '@/model/entity/taxonomy/category';
import { debounce } from 'lodash';

@Component
export default class CategorySelectorComponent extends Vue
{
    @Prop()
    private value!: Category[];

    private categories!: Category[];

    private search: string|null = null;
    private results: Category[] = [];
    private loading = false;

    private created(): void
    {
        this.categories = [ ...this.value ];

        this.results = [ ...this.categories ];
    }

    @Watch('value')
    private onValueChanged()
    {
        this.categories = [ ...this.value ];

        this.results = [ ...this.categories ];
    }

    @Watch('search')
    private debouncedOnSearchChanged = debounce(this.onSearchChanged, 50);

    private async onSearchChanged()
    {
        if (!this.search)
        {
            this.results = [];
            return;
        }

        try
        {
            this.loading = true;

            this.results = await this.$serviceContainer.categoryService.findCategories({
                name: this.search,
            });
        }
        finally
        {
            this.loading = false;
        }
    }

    /**
     * Handles value updates
     */

    private onUpdate()
    {
        this.$emit('input', this.categories);
    }
}