import AbstractTask from '../abstract-task';
import { Method } from 'axios';
import ParamsInterface from '@/model/interface/search/params-interface';
import Tag from '@/model/entity/taxonomy/tag';
import TagSearchConditionsInterface from '@/model/interface/taxonomy/tag-search-conditions-interface';

export default class GetTagsTask extends AbstractTask
{
    public searchConditions?: TagSearchConditionsInterface;

    public async run(): Promise<Tag[]>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            const tags: Tag[] = [];

            for (const member of response.data['hydra:member'])
            {
                const tag = new Tag(member);
                tags.push(tag);
            }

            return tags;
        }
        else
        {
            return [];
        }
    }

    protected get url(): string
    {
        return '/tags';
    }

    protected get method(): Method
    {
        return 'GET';
    }

    protected get authenticated(): boolean
    {
        return false;
    }

    protected get params(): ParamsInterface|null
    {
        if (!this.searchConditions)
        {
            return null;
        }

        return this.generateParamsFromData(this.searchConditions);
    }
}