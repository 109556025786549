import AbstractAuthenticatedService from '../abstract-authenticated-service';
import Card from '@/model/entity/card/card';
import GetCardTask from '@/model/task/card/get-card';

export default class CardService extends AbstractAuthenticatedService
{
    /**
     * Loads a card by id
     *
     * @param id
     */

    public async loadCard(id: string): Promise<Card>
    {
        const task = new GetCardTask();
        task.id = id;

        return task.run();
    }
}