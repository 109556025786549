import { Component, Prop, Vue } from 'vue-property-decorator';

import AssetService from '@/model/service/asset/asset-service';
import Decimal from 'decimal.js-light';
import Element from '@/model/entity/card/element';

@Component
export default class ElementComponent extends Vue
{
    @Prop()
    private element!: Element;

    @Prop()
    private templateOverrides!: any;

    @Prop()
    private pageWidth!: number;

    @Prop()
    private pageHeight!: number;

    private get elementText(): string
    {
        if (this.element.id in this.templateOverrides)
        {
            return this.templateOverrides[this.element.id] ?? '';
        }
        else
        {
            return this.element.config.text?.text ?? '';
        }
    }

    private get containerStyle(): any
    {
        const style: any = {
            top: `${ this.element.config.position.y1 }%`,
            left: `${ this.element.config.position.x1 }%`,
            width: `${ this.width }%`,
            height: `${ this.height }%`,
        };

        if (this.element.config.background?.colour)
        {
            style.backgroundColor = this.element.config.background.colour;
        }

        switch (this.element.config.align.horizontal)
        {
        case 'left':
            style.justifyContent = 'flex-start';
            break;

        case 'centre':
            style.justifyContent = 'center';
            break;

        case 'right':
            style.justifyContent = 'flex-end';
            break;
        }

        switch (this.element.config.align.vertical)
        {
        case 'top':
            style.alignItems = 'flex-start';
            break;

        case 'middle':
            style.alignItems = 'center';
            break;

        case 'bottom':
            style.alignItems = 'flex-end';
            break;
        }

        return style;
    }

    private get elementStyle(): any
    {
        switch (this.element.type)
        {
        case 'image':
            return {
                objectFit: this.element.config.fit,
            };

        case 'text':
            return {
                fontFamily: this.element.config.text?.font ?? 'Arial',
                fontSize: `${ this.textSize }px`,
                lineHeight: 1.25,
                textAlign: this.textAlign,
                color: this.element.config.text?.colour ?? '#000000',
                textShadow: this.element.config.text?.dropShadow ? `${ this.element.config.text.dropShadowDistance }px ${ this.element.config.text.dropShadowDistance }px ${ this.element.config.text.dropShadowBlur }px ${ this.element.config.text.dropShadowColour }` : undefined,
            };

        default:
            return {};
        }
    }

    private get width(): string
    {
        const decimal = new Decimal(this.element.config.position.x2).minus(this.element.config.position.x1);

        return decimal.toFixed(2);
    }

    private get height(): string
    {
        const decimal = new Decimal(this.element.config.position.y2).minus(this.element.config.position.y1);

        return decimal.toFixed(2);
    }

    private get textAlign(): string
    {
        switch (this.element.config.align.horizontal)
        {
        case 'centre':
            return 'center';

        default:
            return this.element.config.align.horizontal;
        }
    }

    private get textSize(): number
    {
        const percentage: number = this.element.config.text?.size ? new Decimal(this.element.config.text.size).toNumber() : 5;

        return new Decimal(this.pageHeight).div(100)
            .mul(percentage)
            .toNumber();
    }

    private get fileUrl(): string|null
    {
        return this.element.asset ? AssetService.getAssetFileUrl(this.element.asset) : null;
    }
}