import Component from 'vue-class-component';
import SearchConditionsInterface from '@/model/interface/search/search-conditions-interface';
import SearchResultsInterface from '@/model/interface/search/search-results-interface';
import Vue from 'vue';

@Component
export default class SearchMixin extends Vue
{
    protected conditions: SearchConditionsInterface|null = null;
    protected results: SearchResultsInterface|null = null;

    /**
     * Resets search conditions
     *
     * @returns
     */

    protected resetSearchConditions(): void
    {
        this.conditions = Object.assign({},
            {
                itemsPerPage: 20,
                page: 1,
            });
    }

    /**
     * Resets search results
     *
     * @returns
     */

    protected resetSearchResults(): void
    {
        this.results = Object.assign({},
            {
                results: [],
                totalResults: 0,
                totalPages: 0,
                page: 1,
                firstPage: null,
                lastPage: null,
                nextPage: null,
                previousPage: null,
            });
    }
}