import AuthenticationService from './authentication/authentication-service';
import Event from '@/event/event';
import { EventBus } from '../helper/event-bus';
import Exception from '../exception/exception';
import TaskInterface from '../task/task-interface';

export default abstract class AbstractAuthenticatedService
{
    protected authenticationService: AuthenticationService;

    /**
     * Constructor
     */

    public constructor(authenticationService: AuthenticationService)
    {
        this.authenticationService = authenticationService;
    }

    /**
     * Runs an authenticated task
     *
     * @param task
     * @returns
     */

    protected async runAuthenticatedTask(task: TaskInterface): Promise<unknown>
    {
        try
        {
            if (!this.authenticationService.isLoggedIn)
            {
                EventBus.$emit(Event.SessionExpired);

                throw new Error(Exception.NotLoggedIn);
            }

            return await task.run();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (error: any)
        {
            switch (error.message)
            {
            case Exception.TokenExpired:

                // Refresh the token
                await this.authenticationService.refreshToken();

                // Re-try the request with the new token
                return task.run();

            default:
                throw error;
            }
        }
    }
}