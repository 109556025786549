import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import { Method } from 'axios';

export default class DeletePageTask extends AbstractTask
{
    public id: string|null = null;

    public async run(): Promise<void>
    {
        const response = await this.execute();

        if (response.status === 204)
        {
            return;
        }

        switch (response.status)
        {
        case 404:
            throw new Error(Exception.TemplatePageNotFound);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/template_pages/${ this.id }`;
    }

    protected get method(): Method
    {
        return 'DELETE';
    }
}