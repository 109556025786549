import { Component, Vue, Watch } from 'vue-property-decorator';

import Category from '@/model/entity/taxonomy/category';
import TemplateIndexComponent from '@/components/template-index/TemplateIndex.vue';

@Component({
    components:
    {
        'app-template-index': TemplateIndexComponent,
    },
})
export default class CategoryView extends Vue
{
    private category: Category|null = null;
    private loading = false;

    private created()
    {
        this.loadCategory();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        // If the slug has changed, the category has changed
        if (this.$route.params.slug !== this.category?.slug)
        {
            this.loadCategory();
        }
    }

    /**
     * Loads the current category
     */

    private async loadCategory()
    {
        this.loading = true;

        try
        {
            // Load the category
            const category = await this.$serviceContainer.categoryService.loadCategoryBySlug(this.$route.params.slug);

            // Set the category
            this.category = category;
        }
        catch (error)
        {
            console.error(error);
        }
        finally
        {
            this.loading = false;
        }
    }
}