import * as particles from '@pixi/particle-emitter';

import ParticleEffectInterface from '../particle-effect-interface';

export default class PetalsEffect implements ParticleEffectInterface
{
    // Variables based on a 700px height card
    private static PARTICLE_MIN_SCALE = 0.2 / 700;
    private static PARTICLE_MAX_SCALE = 0.3 / 700;
    private static PARTICLE_MIN_SPEED = 20 / 700;
    private static PARTICLE_MAX_SPEED = 30 / 700;

    private minStartRotation!: number;
    private maxStartRotation!: number;

    public constructor(minStartRotation = 280, maxStartRotation = 335)
    {
        this.minStartRotation = minStartRotation;
        this.maxStartRotation = maxStartRotation;
    }

    public getParticleImages(): string[]
    {
        return [ require('@/assets/images/particles/nature/rose-petal-1.png'), require('@/assets/images/particles/nature/rose-petal-2.png') ];
    }

    public getParticlesConfig(width: number, height: number): particles.EmitterConfigV3
    {
        // Calculate speeds
        const minSpeed = PetalsEffect.PARTICLE_MIN_SPEED * height;
        const maxSpeed = PetalsEffect.PARTICLE_MAX_SPEED * height;

        // Calculate scale
        const minScale = PetalsEffect.PARTICLE_MIN_SCALE * height;
        const maxScale = PetalsEffect.PARTICLE_MAX_SCALE * height;

        return {
            behaviors: [
                {
                    type: 'scale',
                    config: {
                        scale: {
                            list: [
                                {
                                    value: minScale,
                                    time: 0,
                                },
                                {
                                    value: maxScale,
                                    time: 1,
                                },
                            ],
                        },
                        minMult: 0.5,
                    },
                },
                {
                    type: 'moveSpeed',
                    config: {
                        speed: {
                            list: [
                                {
                                    value: minSpeed,
                                    time: 0,
                                },
                                {
                                    value: maxSpeed,
                                    time: 1,
                                },
                            ],
                        },
                    },
                },
                {
                    type: 'rotation',
                    config: {
                        minSpeed: 30,
                        maxSpeed: 50,
                        minStart: this.minStartRotation,
                        maxStart: this.maxStartRotation,
                    },
                },
                {
                    type: 'spawnShape',
                    config: {
                        type: 'torus',
                        data: {
                            x: -10,
                            y: -10,
                            radius: 2,
                            innerRadius: 0,
                            affectRotation: false,
                        },
                    },
                },
                {
                    type: 'textureRandom',
                    config: {
                        textures: [
                            require('@/assets/images/particles/nature/rose-petal-1.png'),
                            require('@/assets/images/particles/nature/rose-petal-2.png'),
                        ],
                    },
                },
            ],
            lifetime: {
                min: 35,
                max: 35,
            },
            frequency: 2,
            maxParticles: 20,
            pos: {
                x: 0,
                y: 0,
            },
        };
    }

    public getPreWarmTime(): number
    {
        return 20;
    }
}