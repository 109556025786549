import Vue, { PluginObject } from 'vue';

import ServiceContainer from '@/model/service/service-container';
import User from '@/model/entity/organisation/user';

const serviceContainer = new ServiceContainer();

declare module 'vue/types/vue'
{
    interface Vue
    {
        $isGranted(role: string): boolean;
        $serviceContainer: ServiceContainer;
        $user: User|null;
    }
}

const AppPlugin: PluginObject<Vue> =
{
    install(Vue)
    {
        // Role based permission check
        Vue.prototype.$isGranted = function(role: string): boolean
        {
            if (!this.$user?.roles)
            {
                return false;
            }

            return this.$user.roles.includes(role);
        };

        // Service container
        Vue.prototype.$serviceContainer = serviceContainer;

        // Current user
        Object.defineProperties(Vue.prototype, {
            $user:
            {
                get()
                {
                    return this.$store.getters['authentication/user'];
                },
            },
        });
    },
};

Vue.use(AppPlugin);

export default AppPlugin;