import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authentication from './authentication';
import ui from './ui';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules:
    {
        authentication,
        ui,
    },
    plugins: [ vuexLocal.plugin ],
});