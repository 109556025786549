import { Component, Vue, Watch } from 'vue-property-decorator';

import Card from '@/model/entity/card/card';
import CardComponent from '@/components/card/Card.vue';
import EnvelopeComponent from '@/components/envelope/Envelope.vue';

@Component({
    components: {
        'app-card': CardComponent,
        'app-envelope': EnvelopeComponent,
    },
})
export default class CardView extends Vue
{
    private card: Card|null = null;
    private opened = false;

    created(): void
    {
        this.loadCard();
    }

    @Watch('$route')
    private onRouteChanged()
    {
        // If the id has changed, the card has changed
        if (this.$route.params.id !== this.card?.id)
        {
            this.loadCard();
        }
    }

    /**
     * Returns whether the card should be book-like
     */

    private get bookLike(): boolean
    {
        return this.$screen.width >= 1024;
    }

    /**
     * Loads the current card
     */

    private async loadCard()
    {
        const card = await this.$serviceContainer.cardService.loadCard(this.$route.params.id);
        if (!card)
        {
            return;
        }

        // Set the card
        this.card = card;
    }

    /**
     * Handles opening the envelope
     */

    private onEnvelopeOpened()
    {
        this.opened = true;
    }
}