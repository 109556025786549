import { formatISO, parseISO } from 'date-fns';

import CardDto from './card-dto';
import Template from './template';
import User from '../organisation/user';

export default class Card
{
    dto!: CardDto;

    public constructor(dto: CardDto)
    {
        this.dto = Object.assign({}, dto);
    }

    public get id(): string
    {
        return this.dto.id;
    }

    public set id(id: string)
    {
        this.dto.id = id;
    }

    public get template(): Template|null
    {
        if (!this.dto.template)
        {
            return null;
        }

        return new Template(this.dto.template);
    }

    public set template(template: Template|null)
    {
        if (!template)
        {
            this.dto.template = null;
            return;
        }

        this.dto.template = Object.assign({}, template.dto);
    }

    public get templateOverrides(): any
    {
        return this.dto.templateOverrides;
    }

    public set templateOverrides(templateOverrides: any)
    {
        this.dto.templateOverrides = templateOverrides;
    }

    public get recipient(): User|null
    {
        if (!this.dto.recipient)
        {
            return null;
        }

        return new User(this.dto.recipient);
    }

    public set recipient(recipient: User|null)
    {
        if (!recipient)
        {
            this.dto.recipient = null;
            return;
        }

        this.dto.recipient = Object.assign({}, recipient.dto);
    }

    public get addressee(): string
    {
        return this.dto.addressee;
    }

    public set addressee(addressee: string)
    {
        this.dto.addressee = addressee;
    }

    public get sender(): User|null
    {
        if (!this.dto.sender)
        {
            return null;
        }

        return new User(this.dto.sender);
    }

    public set sender(sender: User|null)
    {
        if (!sender)
        {
            this.dto.sender = null;
            return;
        }

        this.dto.sender = Object.assign({}, sender.dto);
    }

    public get dateCreated(): Date
    {
        return parseISO(this.dto.dateCreated);
    }

    public set dateCreated(dateCreated: Date)
    {
        this.dto.dateCreated = formatISO(dateCreated);
    }

    public get dateUpdated(): Date
    {
        return parseISO(this.dto.dateUpdated);
    }

    public set dateUpdated(dateUpdated: Date)
    {
        this.dto.dateUpdated = formatISO(dateUpdated);
    }

    public get dateScheduled(): Date
    {
        return parseISO(this.dto.dateScheduled);
    }

    public set dateScheduled(dateScheduled: Date)
    {
        this.dto.dateScheduled = formatISO(dateScheduled);
    }

    public get dateSent(): Date
    {
        return parseISO(this.dto.dateSent);
    }

    public set dateSent(dateSent: Date)
    {
        this.dto.dateSent = formatISO(dateSent);
    }

    public get dateReceived(): Date
    {
        return parseISO(this.dto.dateReceived);
    }

    public set dateReceived(dateReceived: Date)
    {
        this.dto.dateReceived = formatISO(dateReceived);
    }
}