export default class MathsHelper
{
    /**
     * Calculate the width and height to contain an image inside a boundary whilst maintaining aspect ratio
     *
     * @param srcWidth
     * @param srcHeight
     * @param maxWidth
     * @param maxHeight
     * @returns
     */

    public static containFit(
        srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number,
    ): {width: number, height: number}
    {
        const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return {
            width: srcWidth * ratio,
            height: srcHeight * ratio,
        };
    }

    /**
     * Calculate the width and height cover fit an image inside a boundary whilst maintaining aspect ratio
     *
     * @param srcWidth
     * @param srcHeight
     * @param maxWidth
     * @param maxHeight
     * @returns
     */

    public static coverFit(
        srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number,
    ): {width: number, height: number}
    {
        const ratio = Math.max(maxWidth / srcWidth, maxHeight / srcHeight);

        return {
            width: srcWidth * ratio,
            height: srcHeight * ratio,
        };
    }

    /**
     * Returns a random number between two values (inclusive)
     *
     * @param from
     * @param to
     */

    public static randomNumber(from: number, to: number): number
    {
        return Math.floor((Math.random() * (to - from + 1)) + from);
    }

    /**
     * Linear interpolotation
     *
     * @param start
     * @param end
     * @param amount
     */

    public static lerp(
        start: number, end: number, amount: number,
    ): number
    {
        return ((1 - amount) * start) + (amount * end);
    }
}