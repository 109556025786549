import AbstractTask from '../abstract-task';
import Exception from '@/model/exception/exception';
import Layer from '@/model/entity/card/layer';
import { Method } from 'axios';

export default class PutLayerTask extends AbstractTask
{
    public layer!: Layer;

    public async run(): Promise<Layer>
    {
        const response = await this.execute();

        if (response.status === 200)
        {
            return new Layer(response.data);
        }

        switch (response.status)
        {
        case 400:
            throw new Error(Exception.InvalidDetails);

        default:
            throw new Error(Exception.UnknownError);
        }
    }

    protected get url(): string
    {
        return `/template_layers/${ this.layer.id }`;
    }

    protected get method(): Method
    {
        return 'PUT';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected get data(): {[key: string]: any}|null
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: {[key: string]: any} = Object.assign({}, this.layer.dto);

        if (this.layer.page)
        {
            data.page = `/api/template_pages/${ this.layer.page.id }`;
        }

        return data;
    }
}