import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import ForgottenPasswordForm from '../forgotten-password-form/ForgottenPasswordForm.vue';
import LoginForm from '@/components/login-form/LoginForm.vue';
import RegisterForm from '../register-form/RegisterForm.vue';
import User from '@/model/entity/organisation/user';

@Component({
    components:
    {
        'app-login-form': LoginForm,
        'app-register-form': RegisterForm,
        'app-forgotten-password-form': ForgottenPasswordForm,
    },
})
export default class LoginDialog extends Vue
{
    private static VIEW_LOGIN = 'login';
    private static VIEW_REGISTER = 'register';
    private static VIEW_RESET_PASSWORD = 'reset-password';
    private static VIEW_RESET_PASSWORD_CONFIRMATION = 'reset-password-confirmation';

    @Prop({
        type: Boolean,
        default: false,
    })
    private value!: boolean;

    private view = LoginDialog.VIEW_LOGIN;
    private email = '';

    private loading = false;

    @Watch('value')
    private onValueChanged()
    {
        if (!this.value)
        {
            this.view = LoginDialog.VIEW_LOGIN;
            this.email = '';
        }
    }

    private get visible(): boolean
    {
        return this.value;
    }

    private set visible(visible: boolean)
    {
        this.$emit('input', visible);
    }

    /**
     * Handles back action
     */

    private onBack()
    {
        if (this.loading)
        {
            return;
        }

        this.view = LoginDialog.VIEW_LOGIN;
        this.email = '';
    }

    /**
     * Handles close action
     */

    private onClose()
    {
        if (this.loading)
        {
            return;
        }

        this.visible = false;
    }

    /**
     * Handles show login action
     */

    private onShowLogin()
    {
        this.view = LoginDialog.VIEW_LOGIN;
        this.email = '';
    }

    /**
     * Handles login action
     *
     * @param email
     * @param password
     */

    private async onLogin(email: string, password: string)
    {
        this.loading = true;

        try
        {
            const user = await this.$serviceContainer.authenticationService.login(email, password);
            if (user)
            {
                this.visible = false;
            }
        }
        finally
        {
            this.loading = false;
        }
    }

    /**
     * Handles show reset password action
     *
     * @param email
     */

    private onShowResetPassword(email: string)
    {
        this.email = email;
        this.view = LoginDialog.VIEW_RESET_PASSWORD;
    }

    /**
     * Handles reset password action
     *
     * @param email
     */

    private async onResetPassword(email: string)
    {
        this.loading = true;

        try
        {
            await this.$serviceContainer.authenticationService.forgottenPassword(email);

            this.email = '';
            this.view = LoginDialog.VIEW_RESET_PASSWORD_CONFIRMATION;
        }
        finally
        {
            this.loading = false;
        }
    }

    /**
     * Handles show register action
     *
     * @param email
     */

    private onShowRegister(email: string)
    {
        this.email = email;
        this.view = LoginDialog.VIEW_REGISTER;
    }

    /**
     * Handles register action
     *
     * @param user
     */

    private async onRegister(user: User)
    {
        this.loading = true;

        try
        {
            const newUser = await this.$serviceContainer.authenticationService.register(user);
            if (newUser)
            {
                await this.onLogin(user.email, user.plainPassword ?? '');
                this.visible = false;
            }
        }
        finally
        {
            this.loading = false;
        }
    }
}