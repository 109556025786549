import LayerDto from './layer-dto';
import TemplateDto from './template-dto';

export default class PageDto
{
    id = '';
    template: TemplateDto|null = null;
    position = 1;
    layers: LayerDto[] = [];
    dateCreated = '';
    dateUpdated = '';
}