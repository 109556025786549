import { Component, Vue } from 'vue-property-decorator';

import { EventBus } from '@/model/helper/event-bus';

@Component
export default class Header extends Vue
{
    private get visible(): boolean
    {
        // Hide on the my-card page (for now!)
        if (this.$route.name === 'card')
        {
            return false;
        }

        return true;
    }

    /**
     * Handles navbar toggle action
     */

    private onToggleNavigationDrawer()
    {
        this.$emit('toggle-navigation-drawer');
    }

    /**
     * Handles register action
     */

    private onRegister()
    {
        // Nothing yet
    }

    /**
     * Handles login action
     */

    private onLogin()
    {
        EventBus.$emit('show-login');
    }

    /**
     * Handles logout action
     */

    private onLogout()
    {
        EventBus.$emit('logout');
    }
}